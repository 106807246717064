import { useState, useEffect, memo } from 'react';
import MainCard from 'components/MainCard';
import * as React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// material-ui
import {
    Select,
    SelectChangeEvent,
    FormControl,
    Radio, RadioGroup,
    Box,
    InputLabel,
    InputAdornment,
    Grid,
    List,
    Button,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Stack,
    TextField,
    FormControlLabel,
    Checkbox,
    Typography,
    Switch,
    FormLabel,
    Autocomplete,
    OutlinedInput,
} from '@mui/material';
// import { DatePicker, LocalizationProvider, AdapterDayjs } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Check } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';

import { FieldArray, Field, useFormikContext } from 'formik';

import * as DataBucket from '../../databucket.js';

import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useQuery, useMutation } from '@apollo/client';

import moment from 'moment';

import { GET_USER_DETAILS, GET_ALL_USERS } from 'Queries/Users.js';

// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles({
//     input: {
//         padding: '2px',
//     },
// });

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { enUS } from 'date-fns/locale';

const SaleInformation = memo((props) => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const { SalesInfo, initialValues, setFieldValue } = props;

    let employeeDetails = props.employeeDetails;
    let setEmployeeDetails = props.setEmployeeDetails;

    // console.log('SalesInfo-->', employeeDetails, values, SalesInfo, initialValues)
    const { values, handleChange } = useFormikContext();
    console.log("values", values)
    const Timefield = null;
    console.log("Timefield", Timefield)
    const [age, setAge] = useState('');
    const [assignToUsers, setAssignToUsers] = useState([]);
    const [loginUser, setLoginUser] = useState({});
    const [current, setCurrent] = useState(true)
    const [close, setClose] = useState(!values.lastContactedDate ? true : false);
    // const classes = useStyles();

    const token = localStorage.getItem('token');

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };



    const [getUserDetails, { }] = useMutation(GET_USER_DETAILS, {
        onCompleted(data) {
            // console.log(data.getUserDetails[0], 'getUserDetails-->')
            setLoginUser(data?.getUserDetails[0])
        },
    });





    // console.log('token', parseJwt(token)?.id)


    const getDateTime = (timeValue) => {
        // console.log('getDateTime', timeValue)
        const startDate = new Date(timeValue).toLocaleDateString();
        const startTimeString = new Date(timeValue).toLocaleTimeString(undefined, { hour12: true });

        const dateTimeString = `${startDate} ${startTimeString}`;

        return dateTimeString;
    }
    function formatDateTime(timestamp) {
        const date = new Date(parseInt(timestamp));
        const formattedDate = date.toLocaleString('en-IN', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
            // timeZone: 'UTC',
        });


        return formattedDate;
    }

    const getDueTimeOld = (timeValue, typeValue) => {

        const date = new Date(timeValue).toLocaleDateString();

        if (typeValue == 1) { // 2 hours
            date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
        } else if (typeValue == 2) { // 24 hours
            date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
        } else if (typeValue == 3) { // 48 hours
            date.setTime(date.getTime() + 48);
        }
        return date;
    }
    const getDueTime = (timeValue, typeValue) => {

        const formattedDateTime = moment(timeValue).format('YYYY-MM-DD HH:mm:ss');

        const newDateTime = moment(formattedDateTime).add(typeValue, 'hours').format('YYYY-MM-DD HH:mm:ss');
        // console.log('getDateTime1', newDateTime, timeValue, typeValue, values.date)

        return newDateTime;
    }

    const getDueTimePriority = (emailText) => {
        let hours = 24;
        const hasRushOrUrgent = emailText.toLowerCase().includes("rush") || emailText.toLowerCase().includes("urgent");

        if (hasRushOrUrgent) {
            hours = 2;
            // }else if(){

        }

        return hours;
    }

    const userid = parseJwt(token)?.id

    const { data, } = useQuery(GET_ALL_USERS, {
        variables: { loginUserId: userid },
    });

    let userscontent = data ? data.getAllUsers : [];

    useEffect(() => {

        // console.log("userscontent", userscontent)

        if (userscontent.status == 200) {
            if (userscontent.response?.length > 0) {
                setAssignToUsers(userscontent.response)
                // console.log("userscontent", userscontent.response)

            }
        }

    }, [userscontent]);
    useEffect(() => {
        setFieldValue('lastContactedDate', values.lastContactedDate)
        // setClose(false)
        // setCurrent(false);
        console.log("component is rerendering values is updated")
    }, [values.lastContactedDate])


    useEffect(() => {

        const employeeObj = {
            id: parseJwt(token)?.id,
        }
        getUserDetails({ variables: employeeObj })


        // let priorityValue = getDueTimePriority(RequestInfo.subject + ' ' + RequestInfo.html)

        // if (values.priority == null) {
        //     if (priorityValue == 2) {
        //         setFieldValue('priority', 'Rush')
        //     } else if (priorityValue == 24) {
        //         setFieldValue('priority', 'On Demand')
        //     } else {
        //         setFieldValue('priority', 'Bulk')
        //     }
        // }
    }, []);


    const assignedToHandler = (event) => {
        const checkedId = event.target.value;

        setFieldValue('assignedTo', checkedId);
        if (checkedId == loginUser?.firstname) {
            setFieldValue('requestStatus', "In Progress");
        }
    }

    // const requestStatusHandler = (event) => {
    //     const checkedId = event.target.value;

    //     setFieldValue('timeZone', checkedId);
    //     // if (checkedId == 'In Progress') {
    //     //     setFieldValue('Assign', true);
    //     //     setFieldValue('assignedTo', loginUser?.firstname);
    //     // }
    //     // console.log('values--->', values)
    // }



    const emailSummaryChange = (event) => {
        setFieldValue('subject', event.target.value);

    }

    // const priorityChangeHandler = (event) => {
    //     let priorityValue = event.target.value || ''
    //     setFieldValue('priority', priorityValue);
    //     let dateValue = ''
    //     if (priorityValue == 'Rush') {
    //         dateValue = getDateTime(getDueTime(RequestInfo.startDate, 2))

    //     } else if (priorityValue == 'On Demand') {
    //         dateValue = getDateTime(getDueTime(RequestInfo.startDate, 24));
    //     } else if (priorityValue == 'Bulk') {
    //         dateValue = getDateTime(getDueTime(RequestInfo.startDate, 48));
    //     }
    //     console.log('priorityChangeHandler', dateValue)

    //     setFieldValue('dueDate', dateValue);
    //     console.log('priorityChangeHandler', values, RequestInfo)

    // }

    const assignHandler = (event) => {
        const isChecked = event.target.checked;
        // console.log('assignHandler-->', isChecked)
        setFieldValue('assign', isChecked);
        if (isChecked) {
            setFieldValue('assignedTo', loginUser?.firstname + " " + loginUser?.lastname);
            setFieldValue('assignTo', loginUser?.firstname + " " + loginUser?.lastname);
        } else {
            setFieldValue('assignedTo', '');
            setFieldValue('assignTo', '');
        }
        // console.log("event.target.checked", event.target.checked, values)

    }

    const radiohandleChange = (event) => {
        const checkedId = event.target.value;
        const isChecked = event.target.checked;

        setFieldValue('radioInfo', checkedId);
        // console.log("radioInfo-->", values.radioInfo)

        if (checkedId == 'Completed') {
            if (isChecked) {
                setFieldValue('completedDate', dayjs(new Date()));
                setFieldValue('requestStatus', 'Completed');

            }
            // } else {
            //     setFieldValue('completedDate', '');
        }

        // if (checkedId == 'Pending') {
        //     if (RequestInfo.fromPending == null || RequestInfo.fromPending == false) {
        //         setFieldValue('fromPending', true);
        //     }
        // } else {
        //     if (RequestInfo.fromPending == null || RequestInfo.fromPending == false) {
        //         setFieldValue('fromPending', null);

        //     }
        // }
    }

    const getAssignToUsers = (usersList) => {
        // const user=usersList.find(user => user.id === loginUser.id);
        // console.log(loginUser)
        // const userDepartment=user?.department

        // return usersList
        // .filter(item => {
        //     if (userDepartment === "Operations") {
        //         return item.department === "Operations" || item.department==="Both";
        //     }
        //     else if (userDepartment === "Sales") {
        //         return item.department === "Sales" || item.department==="Both";
        //     }
        //     return true;
        // })
        // .map(item => item.firstname + " " + item.lastname);

        return usersList.map(item => item.firstname + " " + item.lastname);
    }

    const startDateHandler = (date) => {
        // const event = {
        //     target: {
        //         value: date,
        //         name: field.name,
        //     },
        // };
        // field.onChange(event);
        // console.log('followupDate', date)
        setFieldValue('followupDate', date);

    };
    // console.log('getDateTime1', values.date, values)

    const handleAddRow = (arrayHelpers) => {
        // setFieldValue('lastContactedDate', dayjs(new Date()));
        arrayHelpers.push({
            contactPerson: '',
            title: '',
            phone: '',
            email: '',
            remarks: '',
            status: false,
        });
        // console.log("arrayHelpers", arrayHelpers, RequestInfo)
    };

    const handleDeleteRow = (index, arrayHelpers) => {
        arrayHelpers.remove(index);
    };
    const getTimeZoneFinal = (timezonecode) => {
        let finalTimeZone = timezonecode;
        DataBucket.SALES_TIMEZONES?.map((SALES_TIMEZONE) => {
            if (SALES_TIMEZONE?.toLowerCase() == timezonecode?.toLowerCase()) {
                finalTimeZone = SALES_TIMEZONE;
            }
        })
        return finalTimeZone;
    }
    const getTimeZone = (timezonecode) => {
        // console.log('timezonecode?.length', timezonecode?.length, timezonecode)
        if (timezonecode?.length == 3) {
            switch (timezonecode) {
                case 'EST':
                    return "Eastern Time";
                case 'CST':
                    return "Central Time";
                case 'MST':
                    return "Mountain Time";
                case 'PST':
                    return "Pacific Time";
                case 'HST':
                    return "Hawaii Time";
            }
        } else {
            return getTimeZoneFinal(timezonecode);
        }
    }

    const getDateTimeValue = (datevalue) => {
        let finalDate = null;
        if (datevalue != undefined || datevalue != '') {
            if (typeof datevalue === 'string' && datevalue?.length == 13) {
                finalDate = dayjs(parseInt(datevalue))
            } else if (typeof datevalue === 'object') {
                finalDate = datevalue
            }
        }
        return finalDate;
    }
    useEffect(() => {
        if (!values.followup) {
            setFieldValue('followupDate', '');
        }
    }, [values.followup]);

    return (

        <>
            {/* <MainCard title="Lead Information" style={{ marginTop: '10px', marginBottom: '20px' }}> */}
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ArrowDropUpIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: '#bacee7', color: '#000000' }}
                >
                    <Typography variant="h5">Lead Information</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '20px' }}>
                    <Grid container spacing={2}>
                        <Grid container spacing={2} sx={{ mt: 2, ml: 1 }}>
                            <Grid item xs={1}>
                                <Stack>
                                    <FormLabel>Lead ID#</FormLabel>
                                    <FormLabel sx={{ pt: 2 }}>
                                        <Typography fontWeight="bold">{SalesInfo?.leadID || '-'}</Typography>
                                        {/* <Typography fontWeight="bold">123456789</Typography> */}
                                    </FormLabel>
                                </Stack>
                            </Grid>
                            <Grid item xs={1.8}>
                                <Stack>
                                    <FormLabel>Lead Created Date</FormLabel>
                                    <FormLabel sx={{ pt: 2 }}>
                                        {/* <Typography fontWeight="bold">{(values.startDate && getDateTime(values.startDate)) || getDateTime(parseInt(values.date))}</Typography> */}
                                        {/* <Typography fontWeight="bold">08/12/2023 8:57:43 pm</Typography> */}
                                        <Typography fontWeight="bold">{(SalesInfo?.createdDate && formatDateTime(SalesInfo?.createdDate)) || formatDateTime(Date.now())}</Typography>
                                    </FormLabel>
                                </Stack>
                            </Grid>
                            <Grid item xs={1.8}>
                                <Stack >
                                    <FormLabel>Last Updated</FormLabel>
                                    <FormLabel sx={{ pt: 2 }}>
                                        {/* <Typography fontWeight="bold">{(values.startDate && getDateTime(values.startDate)) || getDateTime(parseInt(values.date))}</Typography> */}
                                        {/* <Typography fontWeight="bold">08/12/2023 8:57:43 pm</Typography> */}
                                        <Typography fontWeight="bold">{(SalesInfo?.lastUpdatedDate && formatDateTime(SalesInfo?.lastUpdatedDate)) || formatDateTime(Date.now())}</Typography>
                                    </FormLabel>
                                </Stack>
                            </Grid>
                            <Grid item xs={2}>
                                <Stack >
                                    {/* <FormLabel>Last Contacted</FormLabel> */}
                                    {/* <FormLabel sx={{ pt: 2 }}> */}
                                    {/* <Typography fontWeight="bold">{(values.startDate && getDateTime(values.startDate)) || getDateTime(parseInt(values.date))}</Typography> */}
                                    {/* <Typography fontWeight="bold">08/12/2023 8:57:43 pm</Typography> */}
                                    {/* <Typography fontWeight="bold">{SalesInfo?.lastContactedDate && formatDateTime(SalesInfo?.lastContactedDate)}</Typography> */}
                                    {/* </FormLabel> */}
                                    <FormControl sx={{ minWidth: 120 }} >
                                        <Stack direction="row" justifyContent="space-between">
                                            <FormLabel>Last Contacted</FormLabel>
                                            {close ? (<>
                                                <Button variant="text" size="small" style={{ textTransform: 'none', padding: '0px' }} onClick={() => {
                                                    setFieldValue('lastContactedDate', dayjs(new Date()))
                                                    setClose(false)
                                                    setCurrent(false);
                                                }}>Current</Button>

                                            </>) : (<>

                                                {current ? <Button variant="text" size="small" style={{ textTransform: 'none', padding: '0px' }} onClick={() => {
                                                    setFieldValue('lastContactedDate', dayjs(new Date()))
                                                    setCurrent(false)

                                                }}>Current</Button> : ''}
                                                <Button variant="text" size="small" style={{ textTransform: 'none', padding: '0px' }} onClick={() => {
                                                    setFieldValue('lastContactedDate', null)
                                                    setClose(true)
                                                }}>Clear</Button>

                                            </>)}
                                        </Stack>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Field name="lastContactedDate" error={false}>
                                                {({ field }) => (
                                                    <>
                                                        {console.log("field", field.value)}
                                                        {/* // console.log(dayjs(new Date()).formate('DD-MM-YYYY hh:mm')), */}
                                                        <MobileDateTimePicker
                                                            // timezone={'UTC'}
                                                            format="DD/MM/YYYY hh:mm A"
                                                            name='lastContactedDate'
                                                            // value={dayjs(parseInt(field.value))}
                                                            // value={(field.value && dayjs(parseInt(field.value))) || null}

                                                            value={close ? null : getDateTimeValue(field?.value)}
                                                            // value={field.lastContactedDate}
                                                            onChange={(date) => field.onChange({ target: { value: date, name: field.name } }, setClose(false), setCurrent(true))}
                                                            // onChange={startDateHandler}
                                                            // value={dayjs(field.value) || null}
                                                            renderInput={(params) => (<TextField {...params}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <Button
                                                                                onClick={() => {
                                                                                    setFieldValue('lastContactedDate', null); // Clear date value
                                                                                }}
                                                                                style={{ minWidth: 0 }}
                                                                            >
                                                                                <ClearIcon />
                                                                            </Button>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                variant="outlined" />)}

                                                        />
                                                    </>
                                                )
                                                }
                                            </Field>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            {/* <Grid item xs={(values.followup == true) ? 1 : 2}> */}
                            <Grid item xs={1.4}>
                                <Stack>
                                    <FormControlLabel sx={{ mt: 2 }} value="true" control={<Switch color="success" onChange={handleChange} name="followup" checked={values.followup} />} label="Follow-up" labelPlacement="start" componentsProps={{ typography: { variant: 'h5', color: '#8c8c8c' } }} />

                                </Stack>
                            </Grid>
                            {/* <Grid item xs={(values.followup == true) && 2} style={{ display: (values.followup == true) ? 'block' : 'none' }}> */}
                            <Grid item xs={2}>
                                <Stack>
                                    <FormControl sx={{ minWidth: 120 }}>
                                        <FormLabel>Follow-up Date</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Field name="followupDate" error={false}>
                                                {({ field }) => (
                                                    <DatePicker
                                                        name="followupDate"
                                                        format="DD/MM/YYYY hh:mm A"
                                                        disabled={!values.followup} // Disable if followup is false
                                                        value={values.followup ? getDateTimeValue(field.value) : null}
                                                        onChange={(date) => {
                                                            // Update the date only if followup is true
                                                            if (values.followup) {
                                                                field.onChange({ target: { value: date, name: field.name } });
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </LocalizationProvider>
                                    </FormControl>

                                </Stack>
                            </Grid>
                            {/* <Grid item xs={(values.followup == true) ? 2 : 3}> */}
                            <Grid item xs={2}>
                                <Stack>
                                    <FormControlLabel sx={{ mt: 2 }} value="true" control={<Switch color="success" onChange={handleChange} name="callscheduledwithOPs" checked={values.callscheduledwithOPs} />} label="Call scheduled with Ops. Team" labelPlacement="start" componentsProps={{ typography: { variant: 'h5', color: '#8c8c8c' } }} />

                                </Stack>
                            </Grid>

                        </Grid>

                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item xs={1.5}>
                                <Stack>
                                    <FormControlLabel
                                        sx={{ pr: 1, m: 0.5, mt: 2 }}
                                        value="true"
                                        control={<Checkbox checkedIcon={<Check />} onChange={assignHandler} checked={values.assign}
                                        //  disabled={values.assignedTo} 
                                        />} label="Assign" />

                                </Stack>
                            </Grid>

                            <Grid item xs={1.5}>
                                <Stack>
                                    <FormLabel>Assigned to</FormLabel>
                                    <TextField
                                        name='assignedTo'
                                        value={values.assignedTo || initialValues.assignedTo}
                                        disabled
                                        onChange={handleChange} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} />
                                </Stack>
                            </Grid>

                            <Grid item xs={1.5}>
                                <Stack>
                                    <FormLabel>Assign to</FormLabel>

                                    <Autocomplete
                                        name='assignTo'
                                        options={getAssignToUsers(assignToUsers)}
                                        value={values.assignTo || initialValues.assignTo}
                                        onChange={(event, newValue) => {
                                            setFieldValue('assignTo', newValue);
                                            setFieldValue('assignedTo', newValue);
                                        }}
                                        // getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextField {...params} style={{ padding: '0px' }} />
                                        )}

                                    />
                                </Stack>

                            </Grid>

                            <Grid item xs={1.5}>
                                <Stack>
                                    <FormLabel >Priority</FormLabel>

                                    <Select
                                        value={values.priority}
                                        // value={employeeDetails && employeeDetails['RequestStatus']}
                                        name='priority'
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}>

                                        {DataBucket.SALES_PRIORITY.map((Item, key) => {
                                            return (<MenuItem key={key} value={Item}>{Item}</MenuItem>)
                                        })}

                                    </Select>
                                    {/* <FormHelperText>Without label</FormHelperText> */}
                                </Stack>
                            </Grid>

                            <Grid item xs={1.5}>

                                <Stack>
                                    <FormLabel >Lead Status</FormLabel>

                                    <Select
                                        value={values.status}
                                        // value={employeeDetails && employeeDetails['RequestStatus']}
                                        name='status'
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}>

                                        {DataBucket.SALES_STATUS.map((Item, key) => {
                                            return (<MenuItem key={key} value={Item}>{Item}</MenuItem>)
                                        })}

                                    </Select>
                                    {/* <FormHelperText>Without label</FormHelperText> */}
                                </Stack>
                            </Grid>

                            <Grid item xs={1.5}>

                                <Stack>
                                    <FormLabel >Lead Stage</FormLabel>

                                    <Select
                                        value={values.stage}
                                        // value={employeeDetails && employeeDetails['RequestStatus']}
                                        name='stage'
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}>

                                        {DataBucket.SALES_STAGE.map((Item, key) => {
                                            return (<MenuItem key={key} value={Item}>{Item}</MenuItem>)
                                        })}

                                    </Select>
                                    {/* <FormHelperText>Without label</FormHelperText> */}
                                </Stack>
                            </Grid>

                            <Grid item xs={1.5}>

                                <Stack>
                                    <FormLabel >Lead Channel</FormLabel>

                                    <Select
                                        value={values.channel}
                                        // value={employeeDetails && employeeDetails['RequestStatus']}
                                        name='channel'
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}>

                                        {DataBucket.SALES_CHANNAL.map((Item, key) => {
                                            return (<MenuItem key={key} value={Item}>{Item}</MenuItem>)
                                        })}

                                    </Select>
                                    {/* <FormHelperText>Without label</FormHelperText> */}
                                </Stack>
                            </Grid>

                            <Grid item xs={1.5}>
                                <Stack>
                                    <FormControlLabel sx={{ mt: 2 }} value="true" control={<Switch color="success" onChange={handleChange} name="successfull" checked={values.successfull} />} label="Lead Successfull" labelPlacement="start" componentsProps={{ typography: { variant: 'h5', color: '#8c8c8c' } }} />
                                </Stack>
                            </Grid>
                        </Grid>


                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ArrowDropUpIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: '#bacee7', color: '#000000' }}
                >
                    <Typography variant="h5">Agency Information</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '20px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={2.4}>
                            <Stack >
                                <FormLabel>Name of the Agency</FormLabel>
                                <TextField
                                    name='agencyName'
                                    id='agencyName'
                                    value={values.agencyName}
                                    onChange={handleChange} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} />
                            </Stack>
                        </Grid>
                        <Grid item xs={2.4}>
                            <Stack >
                                <FormLabel>Address</FormLabel>
                                <TextField
                                    name='address'
                                    value={values.address}
                                    onChange={handleChange} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} />
                            </Stack>
                        </Grid>
                        <Grid item xs={2.4}>
                            <Stack >
                                <FormLabel>City</FormLabel>
                                <TextField
                                    name='city'
                                    value={values.city}
                                    onChange={handleChange} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} />
                            </Stack>
                        </Grid>
                        <Grid item xs={2.4}>
                            <Stack >
                                <FormLabel>State</FormLabel>
                                <TextField
                                    name='state'
                                    value={values.state}
                                    onChange={handleChange} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} />
                            </Stack>
                        </Grid>
                        <Grid item xs={2.4}>
                            {/* <Stack >
                            <FormLabel>Send to</FormLabel>
                            <TextField
                                name='sendTo'
                                // value={values.to}
                                onChange={handleChange} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} />
                        </Stack> */}
                            <FormControl sx={{ width: '100%' }}>
                                <FormLabel >TimeZone</FormLabel>

                                <Select
                                    value={getTimeZone(values.timeZone)}
                                    // value={employeeDetails && employeeDetails['RequestStatus']}
                                    name='timeZone'
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}>

                                    {DataBucket.SALES_TIMEZONES.map((Item, key) => {
                                        return (<MenuItem key={key} value={Item}>{Item}</MenuItem>)
                                    })}

                                </Select>
                                {/* <FormHelperText>Without label</FormHelperText> */}
                            </FormControl>
                        </Grid>

                    </Grid>
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={4}>
                            <Stack >
                                <FormLabel>Website URL</FormLabel>
                                <TextField
                                    name='webURL'
                                    value={values.webURL}
                                    onChange={handleChange} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} />
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack >
                                <FormLabel>Contract Information URL</FormLabel>
                                <TextField
                                    name='contactInfoURL'
                                    value={values.contactInfoURL}
                                    onChange={handleChange} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} />
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack >
                                <FormLabel>Linkedin URL</FormLabel>
                                <TextField
                                    name='linkedinURL'
                                    value={values.linkedinURL}
                                    onChange={handleChange} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} />
                            </Stack>
                        </Grid>

                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ArrowDropUpIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: '#bacee7', color: '#000000' }}
                >
                    <Typography variant="h5">Contact Information</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '20px' }}>
                    <Grid container spacing={1} xs={12} >
                        <FieldArray
                            name="leadsContacts"
                            render={(arrayHelpers) => (
                                <>
                                    {values?.leadsContacts?.map((contactinfo, index) => ( //values.existingPolicyInformation
                                        <React.Fragment key={index}>
                                            <Grid container item spacing={2} xs={12} direction="row">
                                                <Grid item xs={2.2}>
                                                    <Stack>
                                                        <FormLabel>Contact Person {index + 1}</FormLabel>
                                                        <TextField
                                                            name={`leadsContacts.${index}.contactPerson`}
                                                            onChange={handleChange}
                                                            value={contactinfo.contactPerson || ''}
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                        />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={2.2}>
                                                    <Stack>
                                                        <FormLabel>Title</FormLabel>
                                                        <TextField
                                                            name={`leadsContacts.${index}.title`}
                                                            onChange={handleChange}
                                                            value={contactinfo.title || ''}
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                        />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={2.2}>
                                                    <Stack>
                                                        <FormLabel>Phone</FormLabel>
                                                        <TextField
                                                            name={`leadsContacts.${index}.phone`}
                                                            onChange={handleChange}
                                                            value={contactinfo.phone || ''}
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                        />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={2.2}>
                                                    <Stack>
                                                        <FormLabel>Email</FormLabel>
                                                        <TextField
                                                            name={`leadsContacts.${index}.email`}
                                                            onChange={handleChange}
                                                            value={contactinfo.email || ''}
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                        />
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={3.2}>
                                                    <Stack>
                                                        <FormLabel>Remarks</FormLabel>
                                                        <TextField
                                                            name={`leadsContacts.${index}.remarks`}
                                                            onChange={handleChange}
                                                            value={contactinfo.remarks || ''}
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                        />
                                                    </Stack>
                                                </Grid>
                                                {/* <Grid item xs={1} container justifyContent="start" alignItems="flex-end">
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => handleDeleteRow(index, arrayHelpers)}
                                                    >
                                                        Delete
                                                    </Button>
                                                </Grid> */}
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                    <Grid item xs={12} m={2} container justifyContent="flex-end">

                                        <Button variant="outlined" style={{ backgroundColor: '#084E81', color: 'white' }} onClick={() => handleAddRow(arrayHelpers)}>
                                            Add Row
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} p={2} backgroundColor='#f0f5fc'>
                                        <FormLabel>Comments</FormLabel>

                                        <OutlinedInput
                                            label="Multiline"
                                            multiline
                                            onChange={handleChange}
                                            name="contactInfoComments"
                                            fullWidth
                                            rows={2}
                                            value={values.contactInfoComments}
                                        />
                                    </Grid>

                                </>
                            )}
                        />

                    </Grid>
                </AccordionDetails>
            </Accordion>
            {/* </MainCard> */}
        </>
        //         </Grid>
        //     </Grid>
        // </MainCard>
    );

})

export default SaleInformation;