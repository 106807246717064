// material-ui
import { Box, IconButton, Link, useMediaQuery, FormLabel } from '@mui/material';
import { GithubOutlined } from '@ant-design/icons';

// project import
import Search from './Search';
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';
import CreateOrder from 'pages/Requests/CreateOrder';

import {
    Grid, Stack, Typography, Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';


import { useState, useEffect } from 'react';
import LoginWorkHistory from 'pages/Requests/LoginWorkHistory';
import { useQuery, useMutation } from '@apollo/client';

import { GET_WORKHISTORY_EQUALS_TIME, UPDATE_WORKHISTORY, CREATE_TIMERHISTORY, GET_TIMERWORKHISTORY_BY_ID } from 'Queries/Orders';
import { useDispatch, useSelector } from 'react-redux';
import { timerHistoryPayloadChange, timerPayloadUpdate } from "../../../../store/reducers/menu";


// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const initialTime = 0;
    // const [startTime, setStartTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [task, settask] = useState("");
    const [istaskRunning, setistaskRunning] = useState(true);
    const [open, setOpen] = useState(false);
    const { timerHistoryPayload } = useSelector((state) => state.menu);
    const taskStartTime = useSelector((state) => state.menu.taskStartTime);

    const dispatch = useDispatch();
    const [elapsedTime, setElapsedTime] = useState(0);
    const [LoggedinTime, setLoggedinTime] = useState(0);
    const [loginWorkHistoryId, setLoginWorkHistoryId] = useState('');
    const [timerData, setTimerData] = useState({});
    // console.log('timerData',timerData)
    const navigate = useNavigate();

    const token = localStorage.getItem('token');
    // const startingTime = Date.now().toString();
    const LoginTime = localStorage.getItem('LoginTime');
    const StartTime = localStorage.getItem('StartTime');
    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };
    const loginWorkHistoryObj = {
        userId: parseJwt(token)?.id,
    }

    const [getTimerWorkHistoryID, { }] = useMutation(GET_TIMERWORKHISTORY_BY_ID, {
        onCompleted(data) {
            let datacontent = data.getTimerWorkHistoryID;
            if (datacontent.length > 0) {
                setLoginWorkHistoryId(datacontent[0].id)
                setTimerData(datacontent[0])
                // setStartTime(datacontent[0].startTime)
                // setStartTime(loginTime)

                setIsRunning(true);
            } else {
                const valuesObject = {
                    id: Math.random().toString(),
                    userId: parseJwt(token)?.id,
                    title: '',
                    description: '',
                    clientName: 'Blueplaint',
                    department: 'Operations',
                    taskType: 'Available',
                    startTime: Date.now().toString(),
                    endTime: Date.now().toString(),
                }
                createTimerHistory({ variables: valuesObject });
            }
        },
    });



    const handleStart = () => {
        // setIsRunning(true);
        // setStartTime(Date.now().toString())
    };
    const handleStop = () => {
        // setistaskRunning(false);
        settask("Stopped")
        localStorage.removeItem("taskType");

        localStorage.setItem('StartTime', new Date().getTime());

        // setElapsedTime(initialTime);

        const valuesObject = {
            id: Math.random().toString(),
            userId: parseJwt(token)?.id,
            title: '',
            description: '',
            clientName: 'Blueplaint',
            department: 'Operations',
            taskType: 'Available',
            startTime: Date.now().toString(),
            endTime: Date.now().toString(),
        }
        dispatch(timerHistoryPayloadChange({ data: valuesObject }))
    };


    const [updateTimerHistory, { }] = useMutation(UPDATE_WORKHISTORY, {
        onCompleted(data) {
            if (data?.updateWorkHistory?.status == '200') {
                if (timerHistoryPayload?.logOut) {
                    dispatch(timerPayloadUpdate());
                    localStorage.clear();
                    navigate('/')
                } else {
                    createTimerHistory({ variables: timerHistoryPayload });
                }
            } else {
                alert('An Error Occurred while Updating Timer');
            }
        },
    });

    const [createTimerHistory, { }] = useMutation(CREATE_TIMERHISTORY, {
        onCompleted(data) {
            console.log("data", data)
            if (data?.createTimerHistory?.status == '200') {
                setLoginWorkHistoryId(data.createTimerHistory.id);
                setTimerData(data?.createTimerHistory);
                setIsRunning(true);
                // setStartTime(data?.createTimerHistory?.startTime);
                dispatch(timerPayloadUpdate());
            } else {
                alert('An Error Occurred while Updating Timer');
            }
        },
    });
    useEffect(() => {
        if (Object.keys(timerHistoryPayload).length) {
            const payload = {
                id: loginWorkHistoryId,
                endTime: Date.now().toString()
            }
            updateTimerHistory({ variables: payload })
        }
    }, [timerHistoryPayload]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formatTime = (time) => {
        const seconds = Math.floor(time / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(months / 12);

        const formattedTime = [
            years > 0 ? `${years} year${years > 1 ? 's' : ''}` : '',
            months > 0 ? `${months} month${months > 1 ? 's' : ''}` : '',
            days > 0 ? `${days} day${days > 1 ? 's' : ''}` : '',
            `${hours % 24}:${minutes % 60}:${seconds % 60}`,
        ].filter(Boolean).join(' ');

        return formattedTime;
    };

    useEffect(() => {
        getTimerWorkHistoryID({ variables: loginWorkHistoryObj });
    }, []);

    useEffect(() => {
        let intervalId;
        if (isRunning) {
            intervalId = setInterval(() => {
                const currentTime = new Date().getTime();
                // const timeDifference = currentTime - parseInt(startTime);
                const LogintimeDifference = currentTime - parseInt(LoginTime);
                // setElapsedTime(timeDifference);
                setLoggedinTime(LogintimeDifference);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [isRunning]);
    useEffect(() => {

        // setStartTime(startingTime)
        // console.log(startingTime)
        let intervalId;
        if (istaskRunning) {
            const StartTime = localStorage.getItem('StartTime');
            intervalId = setInterval(() => {
                const currentTime = new Date().getTime();
                const timeDifference = currentTime - parseInt(StartTime);
                // const LogintimeDifference = currentTime - parseInt(loginTime);
                setElapsedTime(timeDifference);
                // setLoggedinTime(LogintimeDifference);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [task, istaskRunning, taskStartTime]);


    const jsxContent = (
        <>
            {!matchesXs && <Search />}
            {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
            <Grid container spacing={1}>
                <Grid item xs={4} spacing={1}>
                    <Stack >
                        <FormLabel>Company</FormLabel>
                        <FormLabel sx={{ pt: 0.5 }}><Typography fontWeight="bold">{timerData?.clientName}</Typography></FormLabel>
                    </Stack>
                </Grid>
                <Grid item xs={3} spacing={1}>
                    <Stack >
                        <FormLabel>Department</FormLabel>
                        <FormLabel sx={{ pt: 0.5 }}><Typography fontWeight="bold">{timerData?.department || timerData?.processType}</Typography></FormLabel>
                    </Stack>
                </Grid>
                <Grid item xs={2} spacing={1}>
                    <Stack >
                        <FormLabel>Task</FormLabel>
                        <FormLabel sx={{ pt: 0.5 }}><Typography fontWeight="bold">{timerData?.taskType}</Typography></FormLabel>
                    </Stack>
                </Grid>
                {timerData?.requestId &&
                    <Grid item xs={2} spacing={1}>
                        <Stack >
                            <FormLabel>Request Id</FormLabel>
                            <FormLabel sx={{ pt: 0.5 }}><Typography fontWeight="bold">{timerData?.requestId}</Typography></FormLabel>
                        </Stack>
                    </Grid>}
            </Grid>

            {open && <LoginWorkHistory open={open} handleClose={handleClose} handleStart={handleStart} setLoginWorkHistoryId={setLoginWorkHistoryId} setistaskRunning={setistaskRunning} settask={settask} />}
            <Stack direction="row" spacing={2}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                        <div style={{ display: "flex", flexDirection: "column" }}> <span style={{ fontSize: "10px", fontWeight: "bold", width: "100px" }}>{timerData?.taskType} Duration</span>
                            <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>{formatTime(elapsedTime)}</Typography></div>
                        <div style={{ display: "flex", flexDirection: "column" }}> <span style={{ fontSize: "10px", fontWeight: "bold", width: "100px" }}>Login Duration</span>
                            <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>{formatTime(LoggedinTime)}</Typography></div>
                        {isRunning && (
                            <Button style={{ backgroundColor: '#D4B84A', color: 'white', marginRight: '10px' }} onClick={handleStop}>Stop</Button>
                        )}
                        <Button style={{ backgroundColor: '#084E81', color: 'white', marginRight: '10px' }} onClick={handleOpen}>Start</Button>
                    </Stack>
                </Grid>
            </Stack>
            <IconButton
                component={Link}
                href="https://github.com/codedthemes/mantis-free-react-admin-template"
                target="_blank"
                disableRipple
                color="secondary"
                title="Download Free Version"
                sx={{ color: 'text.primary', bgcolor: 'grey.100' }}
            >

                <GithubOutlined />
            </IconButton>

            <Notification />
            {!matchesXs && <Profile />}
            {matchesXs && <MobileSection />}
        </>
    );

    return [jsxContent, handleStop];
};

export default HeaderContent;
