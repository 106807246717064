import { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import MainCard from 'components/MainCard';
import { BlueplanitModal, BlueplanitModalTitle } from 'components/BlueplanitModal';
import DialogContent from '@mui/material/DialogContent';
// material-ui
import {
    Select,
    FormControl,
    OutlinedInput,
    InputLabel,
    Grid,
    List,
    Button,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Stack,
    TextField,
    FormControlLabel,
    Checkbox,
    Typography,
    Switch,
    FormLabel
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { Formik } from 'formik';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useQuery, useMutation } from '@apollo/client';
import * as DataBucket from '../../databucket'

import { CREATE_WORKHISTORY, } from "../../Queries/Orders";
import { timerHistoryPayloadChange,settaskStartTime } from "../../store/reducers/menu";
import { useDispatch, useSelector } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
};

const CreateOrder = (props) => {
    const { open, handleClose, handleStart, RequestInfo, setWorkHistoryId } = props;

    const dispatch = useDispatch();


    const initialValues = {

        requestId: RequestInfo.requestId,
        clientName: '',
        processType: '',
        taskType: '',
        email: '',
        subject: '',
        body: '',
    }
    const token = localStorage.getItem('token');

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    const submit = (values) => {
        console.log(values)
        handleClose()

        const valuesObject = {
            id: Math.random().toString(),
            userId: parseJwt(token)?.id,
            emailId: RequestInfo.id,
            requestId: RequestInfo.requestId || '',
            clientName: '',
            processType: values.processType,
            taskType: values.taskType || 'Processing',
            email: values?.email,
            subject: values?.subject,
            body: values?.body,
            startTime: Date.now().toString(),
            endTime: Date.now().toString(),
        }
        dispatch(timerHistoryPayloadChange({data: valuesObject}));
        localStorage.setItem('StartTime', new Date().getTime());
        dispatch(settaskStartTime(new Date().getTime()))
        handleStart();
    }

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            <BlueplanitModal
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <BlueplanitModalTitle id="customized-dialog-title" onClose={handleClose}>
                    Modal title
                </BlueplanitModalTitle>
                <DialogContent dividers>
                    <Grid item xs={12} mt={2}>

                        <Formik
                            initialValues={initialValues}
                            onSubmit={submit}>
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} spacing={1}>
                                            <Stack >
                                                <FormLabel>Request#</FormLabel>
                                                <FormLabel sx={{ pt: 2 }}><Typography fontWeight="bold">{RequestInfo.requestId}</Typography></FormLabel>
                                            </Stack>


                                        </Grid>
                                        <Grid item xs={3} spacing={1}>
                                            <Stack >
                                                <FormLabel>Client Name</FormLabel>
                                                <FormLabel sx={{ pt: 2 }}><Typography fontWeight="bold">{RequestInfo.requestId}</Typography></FormLabel>
                                            </Stack>


                                        </Grid>
                                        <Grid item xs={3} spacing={1}>
                                            <FormControl sx={{ minWidth: 220 }}>
                                                <FormLabel>Service</FormLabel>

                                                <Select
                                                    value={values.processType}
                                                    name='processType'
                                                    // value={employeeDetails && employeeDetails['ProcessType']}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}>

                                                    {DataBucket.PROCESS_TYPE_CONFIG.map((Item, key) => {
                                                        return (<MenuItem key={key} value={Item}>{Item}</MenuItem>)
                                                    })}

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3} spacing={1}>
                                            <FormControl sx={{ minWidth: 220 }}>
                                                <FormLabel>Task</FormLabel>

                                                <Select
                                                    value={values.taskType || 'Processing'}
                                                    name='taskType'
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}>

                                                    {DataBucket.TASKS_TO_CONFIG.map((Item, key) => {
                                                        return (<MenuItem key={key} value={Item}>{Item}</MenuItem>)
                                                    })}

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="email-login">Email Address</InputLabel>
                                                <OutlinedInput
                                                    id="email-login"
                                                    type="email"
                                                    name="email"
                                                    onChange={handleChange}
                                                    placeholder="Enter email address"
                                                    fullWidth
                                                />

                                            </Stack>


                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="email-login">Subject</InputLabel>
                                                <OutlinedInput
                                                    id="email-login"
                                                    type="text"
                                                    name="subject"
                                                    onChange={handleChange}
                                                    placeholder="Enter email subject"
                                                    fullWidth
                                                />

                                            </Stack>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} mt={2}>
                                        <InputLabel htmlFor="email-login">Body</InputLabel>
                                        <OutlinedInput
                                            id="outlined-multiline-flexible"
                                            label="Multiline"
                                            multiline
                                            onChange={handleChange}
                                            name="body"
                                            maxRows={4}
                                            fullWidth
                                            rows={2}
                                        />
                                    </Grid> */}

                                    <DialogActions>
                                        <Button variant="outlined" onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <Button variant="contained" type="submit" >
                                            Save
                                        </Button>

                                    </DialogActions>
                                </form>
                            )}


                        </Formik>

                    </Grid>

                </DialogContent>

            </BlueplanitModal>
        </>
    )
}
export default CreateOrder;