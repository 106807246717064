import { gql } from '@apollo/client';


export const LEADS_INPUT = gql`
mutation InsertLeads($leads: [leadTypeInput]!) {
    insertLeads(leads: $leads) {
    status
    message
  }
}
`;

export const LEADS_FILTERS = gql`
query GetLeadFilters($filters:leadfiltersInput!){
  getLeadFilters(filters:$filters) {
    responsestatus
    message
    agencyName
    state
    city
    channel
    assignedTo
    timeZone
    followup
    stage
    status
    callscheduledwithOPs
    successfull
    priority
  }
}
`;


// export const GET_ALL_LEADS = gql`
//   query {
//     getAllLeads {
//       status
//       message
//       response{
//         id
//         leadID
//         createdDate
//         lastUpdatedDate
//         lastContactedDate
//         followup
//         followupDate
//         callscheduledwithOPs
//         assign
//         assignedTo
//         assignTo
//         priority
//         status
//         stage
//         channel
//         successfull
//         agencyName
//         address
//         city
//         state
//         timeZone
//         webURL
//         contactInfoURL
//         linkedinURL
//         contactInfoComments
//         callingComments
//         emailComments
//         linkedinComments
//         scheduledCallComments
//         opsTeamComments
//         leadsContacts{
//           contactPerson
//           title
//           phone
//           email
//           remarks
//           status
//         }
//         leadsCalls{
//           date
//           time
//           status
//           spokeWith
//           callby
//           remarks
//         }
//         leadsEmails{
//           date
//           time
//           status
//           emailedto
//           emailedy
//           remarks
//           emailFollowup
//         }
//         leadsLinkedins{
//           date
//           time
//           status
//           requestTo
//           requestBy
//           remarks
//         }
//         leadsScheduledCalls{
//           date
//           time
//           callType
//           emails
//           phonenumber
//           callScheduledBy
//           remarks
//         }
//         leadsOPS{
//           date
//           time
//           callType
//           emails
//           phonenumber
//           callScheduledBy
//           remarks
//           callConnected
//           clientAttendees
//           blueplanitAttendees
//           amsusedbyClient
//           clientRequirements
//           pros
//           cons
//           emailSent
//         }
//       }

//     }
//   }
// `;

export const GET_ALL_LEADS = gql`
  query GetAllLeads($page: Int!, $pageSize: Int!, $filters:leadfiltersInput!, $sort:leadfiltersInput!){
    getAllLeads(page: $page, pageSize: $pageSize, filters:$filters, sort:$sort) {
      status
      message
      count
      response{
        id
        leadID
        createdDate
        createdBy
        lastUpdatedDate
        lastContactedDate
        followup
        followupDate
        callscheduledwithOPs
        assign
        assignedTo
        assignTo
        priority
        status
        stage
        channel
        successfull
        agencyName
        address
        city
        state
        timeZone
        webURL
        contactInfoURL
        linkedinURL
        contactInfoComments
        callingComments
        emailComments
        linkedinComments
        scheduledCallComments
        opsTeamComments
        leadsContacts{
          contactPerson
          title
          phone
          email
          remarks
          status
        }
        leadsCalls{
          date
          time
          status
          spokeWith
          callby
          emailSent
          remarks
        }
        leadsEmails{
          date
          time
          status
          emailedto
          emailedy
          remarks
          emailFollowup
        }
        leadsLinkedins{
          date
          time
          status
          requestTo
          requestBy
          remarks
        }
        leadsScheduledCalls{
          date
          time
          callType
          emails
          phonenumber
          callScheduledBy
          timeZone
          remarks
        }
        leadsOPS{
          date
          time
          callType
          emails
          phonenumber
          callScheduledBy
          remarks
          callConnected
          clientAttendees
          blueplanitAttendees
          amsusedbyClient
          clientRequirements
          pros
          cons
          emailSent
        }
      }

    }
  }
`;
export const UPDATE_LEAD = gql`
  mutation (
    $id: String!, 
    $leadID: Int,
    $createdDate: String,
    $createdBy: String,
    $lastUpdatedDate: String,
    $lastContactedDate: String,
    $followup: Boolean,
    $followupDate: String,
    $callscheduledwithOPs: Boolean,
    $assign: Boolean,
    $assignedTo: String,
    $assignTo: String,
    $priority: String,
    $status: String,
    $stage: String,
    $channel: String,
    $successfull: Boolean,
    $agencyName: String,
    $address: String,
    $city: String,
    $state: String,
    $timeZone: String,
    $webURL: String,
    $contactInfoURL: String,
    $linkedinURL: String,
    $contactInfoComments: String,
    $callingComments: String,
    $emailComments: String,
    $linkedinComments: String,
    $scheduledCallComments: String,
    $opsTeamComments: String,
    $leadsContacts: [LeadsContactsInput],
    $leadsCalls: [LeadsCallsInput],
    $leadsEmails: [LeadsEmailsInput],
    $leadsLinkedins: [LeadsLinkedinsInput],
    $leadsScheduledCalls: [LeadsScheduledCallsInput],
    $leadsOPS: [LeadsOPSInput],
    ) {
    updateLead(
      id: $id, 
      leadID:$leadID,
      createdDate:  $createdDate,
      createdBy:  $createdBy,
      lastUpdatedDate:  $lastUpdatedDate,
      lastContactedDate:  $lastContactedDate,
      followup:  $followup,
      followupDate:  $followupDate,
      callscheduledwithOPs:  $callscheduledwithOPs,
      assign:  $assign,
      assignedTo:  $assignedTo,
      assignTo:  $assignTo,
      priority:  $priority,
      status:  $status,
      stage:  $stage,
      channel:  $channel,
      successfull:  $successfull,
      agencyName:  $agencyName,
      address:  $address,
      city:  $city,
      state:  $state,
      timeZone:  $timeZone,
      webURL:  $webURL,
      contactInfoURL:  $contactInfoURL,
      linkedinURL:  $linkedinURL,
      contactInfoComments:  $contactInfoComments,
      callingComments:  $callingComments,
      emailComments:  $emailComments,
      linkedinComments:  $linkedinComments,
      scheduledCallComments:  $scheduledCallComments,
      opsTeamComments:  $opsTeamComments,
      leadsContacts:  $leadsContacts,
      leadsCalls:  $leadsCalls,
      leadsEmails:  $leadsEmails,
      leadsLinkedins:  $leadsLinkedins,
      leadsScheduledCalls:  $leadsScheduledCalls,
      leadsOPS:  $leadsOPS,
      ) {
        id
        leadID
        createdDate
        createdBy
        lastUpdatedDate
        lastContactedDate
        followup
        followupDate
        callscheduledwithOPs
        assign
        assignedTo
        assignTo
        priority
        status
        stage
        channel
        successfull
        agencyName
        address
        city
        state
        timeZone
        webURL
        contactInfoURL
        linkedinURL
        contactInfoComments
        callingComments
        emailComments
        linkedinComments
        scheduledCallComments
        opsTeamComments
        leadsContacts{
          contactPerson
          title
          phone
          email
          remarks
          status
        }
        leadsCalls{
          date
          time
          status
          spokeWith
          callby
          emailSent
          remarks
        }
        leadsEmails{
          date
          time
          status
          emailedto
          emailedy
          remarks
          emailFollowup
        }
        leadsLinkedins{
          date
          time
          status
          requestTo
          requestBy
          remarks
        }
        leadsScheduledCalls{
          date
          time
          callType
          emails
          phonenumber
          callScheduledBy
          timeZone
          remarks
        }
        leadsOPS{
          date
          time
          callType
          emails
          phonenumber
          callScheduledBy
          remarks
          callConnected
          clientAttendees
          blueplanitAttendees
          amsusedbyClient
          clientRequirements
          pros
          cons
          emailSent
        }
      
    }
  }
`;

export const CREATE_LEAD = gql`
  mutation (
    $createdDate: String,
    $createdBy: String,
    $lastUpdatedDate: String,
    $lastContactedDate: String,
    $followup: Boolean,
    $followupDate: String,
    $callscheduledwithOPs: Boolean,
    $assign: Boolean,
    $assignedTo: String,
    $assignTo: String,
    $priority: String,
    $status: String,
    $stage: String,
    $channel: String,
    $successfull: Boolean,
    $agencyName: String,
    $address: String,
    $city: String,
    $state: String,
    $timeZone: String,
    $webURL: String,
    $contactInfoURL: String,
    $linkedinURL: String,
    $contactInfoComments: String,
    $callingComments: String,
    $emailComments: String,
    $linkedinComments: String,
    $scheduledCallComments: String,
    $opsTeamComments: String,
    $leadsContacts: [LeadsContactsInput],
    $leadsCalls: [LeadsCallsInput],
    $leadsEmails: [LeadsEmailsInput],
    $leadsLinkedins: [LeadsLinkedinsInput],
    $leadsScheduledCalls: [LeadsScheduledCallsInput],
    $leadsOPS: [LeadsOPSInput],
    ) {
      createLead(
        createdDate:  $createdDate,
        createdBy:  $createdBy,
        lastUpdatedDate:  $lastUpdatedDate,
        lastContactedDate:  $lastContactedDate,
        followup:  $followup,
        followupDate:  $followupDate,
        callscheduledwithOPs:  $callscheduledwithOPs,
        assign:  $assign,
        assignedTo:  $assignedTo,
        assignTo:  $assignTo,
        priority:  $priority,
        status:  $status,
        stage:  $stage,
        channel:  $channel,
        successfull:  $successfull,
        agencyName:  $agencyName,
        address:  $address,
        city:  $city,
        state:  $state,
        timeZone:  $timeZone,
        webURL:  $webURL,
        contactInfoURL:  $contactInfoURL,
        linkedinURL:  $linkedinURL,
        contactInfoComments:  $contactInfoComments,
        callingComments:  $callingComments,
        emailComments:  $emailComments,
        linkedinComments:  $linkedinComments,
        scheduledCallComments:  $scheduledCallComments,
        opsTeamComments:  $opsTeamComments,
        leadsContacts:  $leadsContacts,
        leadsCalls:  $leadsCalls,
        leadsEmails:  $leadsEmails,
        leadsLinkedins:  $leadsLinkedins,
        leadsScheduledCalls:  $leadsScheduledCalls,
        leadsOPS:  $leadsOPS,
      ) {
        leadID
        createdDate
        createdBy
        lastUpdatedDate
        lastContactedDate
        followup
        followupDate
        callscheduledwithOPs
        assign
        assignedTo
        assignTo
        priority
        status
        stage
        channel
        successfull
        agencyName
        address
        city
        state
        timeZone
        webURL
        contactInfoURL
        linkedinURL
        contactInfoComments
        callingComments
        emailComments
        linkedinComments
        scheduledCallComments
        opsTeamComments
        leadsContacts{
          contactPerson
          title
          phone
          email
          remarks
          status
        }
        leadsCalls{
          date
          time
          status
          spokeWith
          callby
          emailSent
          remarks
        }
        leadsEmails{
          date
          time
          status
          emailedto
          emailedy
          remarks
          emailFollowup
        }
        leadsLinkedins{
          date
          time
          status
          requestTo
          requestBy
          remarks
        }
        leadsScheduledCalls{
          date
          time
          callType
          emails
          phonenumber
          callScheduledBy
          timeZone
          remarks
        }
        leadsOPS{
          date
          time
          callType
          emails
          phonenumber
          callScheduledBy
          remarks
          callConnected
          clientAttendees
          blueplanitAttendees
          amsusedbyClient
          clientRequirements
          pros
          cons
          emailSent
        }
      
    }
  }
`;

export const GET_LEAD_BY_ID = gql`
  mutation ($id: String!) {
    getLeadById(id: $id) {
      id
        leadID
        createdDate
        createdBy
        lastUpdatedDate
        lastContactedDate
        followup
        followupDate
        callscheduledwithOPs
        assign
        assignedTo
        assignTo
        priority
        status
        stage
        channel
        successfull
        agencyName
        address
        city
        state
        timeZone
        webURL
        contactInfoURL
        linkedinURL
        contactInfoComments
        callingComments
        emailComments
        linkedinComments
        scheduledCallComments
        opsTeamComments
        leadsContacts{
          contactPerson
          title
          phone
          email
          remarks
          status
        }
        leadsCalls{
          date
          time
          status
          spokeWith
          callby
          emailSent
          remarks
        }
        leadsEmails{
          date
          time
          status
          emailedto
          emailedy
          remarks
          emailFollowup
        }
        leadsLinkedins{
          date
          time
          status
          requestTo
          requestBy
          remarks
        }
        leadsScheduledCalls{
          date
          time
          callType
          emails
          phonenumber
          callScheduledBy
          timeZone
          remarks
        }
        leadsOPS{
          date
          time
          callType
          emails
          phonenumber
          callScheduledBy
          remarks
          callConnected
          clientAttendees
          blueplanitAttendees
          amsusedbyClient
          clientRequirements
          pros
          cons
          emailSent
        }
    }
  }
`;

export const CREATE_LEAD_FILTER = gql`
  mutation (
    $userID: String!
    $filterName: String!
    $filterString:String!
    $filterStatus:Boolean!
   
    ) {
      createLeadFilters(
        userID: $userID
        filterName: $filterName
        filterString: $filterString
        filterStatus: $filterStatus) {
          id
          userID
          filterName
          filterString
          filterStatus
        }
      }
    `;

export const GET_ALL_LEAD_FILTERS_BY_ID = gql`
    mutation ($userID: String!) {
      getAllLeadFilterByID(userID: $userID) {
        id
        userID
        filterName
          filterString
          filterStatus
      }
    }
  `;

export const CREATE_LEAD_WORKHISTORY = gql`
  mutation (
    $leadId: String!
    $userName: String!
    $leadStatus:String!
    $changeTime:String!
    ) {
      createLeadWorkHistory(
        leadId: $leadId
        userName: $userName
        leadStatus: $leadStatus
        changeTime: $changeTime) {
          id
          leadId
          userName
          leadStatus
          changeTime
        }
      }
    `;

export const GET_ALL_LEAD_WORKHISTORY_BY_ID = gql`
    mutation ($leadId: String!) {
      getAllLeadWorkHistoryByID(leadId: $leadId) {
        id
        leadId
        userName
        leadStatus
        changeTime
      }
    }
  `;