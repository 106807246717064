import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';

import { Stack, Chip, Typography } from '@mui/material';

import BlueplanitLogo from "./../../BlueplanitLogo"

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from 'components/Logo';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
    const theme = useTheme();

    return (
        // only available in paid version
        <DrawerHeaderStyled theme={theme} open={open}>
            <Stack direction="row" spacing={1} alignItems="center">
                {/* <Typography variant="h6" color="textSecondary">
                    Satellite
                </Typography> */}
                <img src={BlueplanitLogo} height='50px' style={{ borderColor: '#000000' }} />

                {/* <Logo />
                <Chip
                    label={process.env.REACT_APP_VERSION}
                    size="small"
                    sx={{ height: 16, '& .MuiChip-label': { fontSize: '0.625rem', py: 0.25 } }}
                    component="a"
                    href="https://github.com/codedthemes/mantis-free-react-admin-template"
                    target="_blank"
                    clickable
                /> */}
            </Stack>
        </DrawerHeaderStyled>
    );
};

DrawerHeader.propTypes = {
    open: PropTypes.bool
};

export default DrawerHeader;
