import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { timerHistoryPayloadChange } from 'store/reducers/menu';

const IdleTimer = () => {
  const [isIdle, setIsIdle] = useState(false);
  let idleTimer = null;
  const dispatch = useDispatch()

  const resetTimer = () => {
    setIsIdle(false);
    if (idleTimer) {
      clearTimeout(idleTimer);
    }
    idleTimer = setTimeout(() => {
      setIsIdle(true);
      // Log out the user
      handleLogout();
    }, 3600000);
  };

  const handleLogout = async () => {
    const payload = {
        logOut: true
    }
     dispatch(timerHistoryPayloadChange({data: payload}));
};

  useEffect(() => {
    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('keydown', resetTimer);
    document.addEventListener('click', resetTimer);

    resetTimer(); 

    return () => {
      clearTimeout(idleTimer);
      document.addEventListener('mousemove', resetTimer);
      document.addEventListener('keydown', resetTimer);
      document.addEventListener('click', resetTimer);
    };
  }, []);

  return (
    <div>
      {isIdle && <p>You have been logged out due to inactivity.</p>}
    </div>
  );
};

export default IdleTimer;




