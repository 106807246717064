import React, { useState, useEffect } from 'react';
import { ApiContext } from './ApiContext';
import axios from 'axios';
// import * as ApiServices from "../../services/ApiServices";
import { useNavigate } from "react-router-dom";

import { GET_ALL_ORDERS, } from "../../Queries/Orders.js";
import { useQuery, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from "react-redux";
// import { getOrders } from "../../store/reducers/ordersSlice";

import { getOrders,getReportData,getSummaryData,TotalCount } from 'store/reducers/ordersSlice';


function Store({ children }) {
  const [page,setPage] = useState(1);
  const [pageSize,setPageSize] = useState(50);
  const [filter,setFilter] = useState({filter:"Inbox"});
  const [isAllOrders,setIsAllOrders] = useState(false);
  const dispatch = useDispatch();
  const history = useNavigate()
  const [appId, setAppId] = useState('');
  const [requestDetails, setRequestDetails] = useState({});

  const { data, refetch, loading, error } = useQuery(GET_ALL_ORDERS,{
    variables: {page: page, pageSize: pageSize, filters: filter, isAllOrders:isAllOrders}
  });

  const datacontent = data && data.getAllOrders;

  console.log("datacontentdatacontent--->", datacontent)

  useEffect(() => {
    // if (datacontent && datacontent.status == 401) {
    //   history("/login")
    // }
    const fetchData = async () => {
      if (datacontent?.response?.length >= 0) {
        await dispatch(getOrders(datacontent.response));
        await dispatch(getReportData(datacontent.response));
        await dispatch(TotalCount(datacontent.totalRecords));
        await dispatch(getSummaryData(datacontent.summary ));
      }
    };

    fetchData();
  }, [datacontent, dispatch])

 
  // const fetchDataFromService = async () => {

  //   setIsPageLoading(true)


  // };

  // useEffect(() => {
  //   (async () => {
  //     await fetchDataFromService();
  //   })();
  // }, []);






  //value={{ requestDetails, setRequestDetails, appId, setAppId }}
  return (<>
    <ApiContext.Provider value={{ requestDetails, setRequestDetails, appId, setAppId, data, refetch, page, setPage, pageSize, setFilter, loading, isAllOrders, setIsAllOrders}}>
      {children}
    </ApiContext.Provider>
  </>
  )
}
export default Store