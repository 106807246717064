import React, { useState, useEffect,memo } from 'react';

import { Grid, FormLabel, Typography, FormControl, Select, MenuItem, Stack, TextField, Button, OutlinedInput, FormControlLabel, Switch, Divider } from '@mui/material';
import { Formik, FieldArray, Field,useFormikContext } from 'formik';

import * as DataBucket from '../../databucket';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
// import { useQuery } from '@apollo/client';

// import { GET_ALL_USERS } from 'Queries/Users.js';

const CallingTrack = memo((props) => {
    const {  SalesInfo, assignToUsers, getBy, setFieldValue } = props;
    const { values,handleChange } = useFormikContext();
    const token = localStorage.getItem('token');

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    // const [assignToUsers, setAssignToUsers] = useState([]);

    // const { data, } = useQuery(GET_ALL_USERS);

    // let userscontent = data ? data.getAllUsers : [];

    // useEffect(() => {

    //     console.log("userscontent", userscontent)

    //     if (userscontent.status == 200) {
    //         if (userscontent.response?.length > 0) {
    //             setAssignToUsers(userscontent.response)
    //             console.log("userscontent", userscontent.response)

    //         }
    //     }

    // }, [userscontent]);

    const handleAddRow = (arrayHelpers) => {
        setFieldValue('lastContactedDate', dayjs(new Date()));
        arrayHelpers.push({
            // date: dayjs(new Date()) || '',
            date: dayjs(new Date()) || '',
            time: dayjs().set('second', 0).set('millisecond', 0) || '',
            status: "Attempt",
            spokeWith: '',
            callby: getBy(null),
            emailSent: false,
            remarks: '',
        });
        console.log("arrayHelpers", arrayHelpers, parseJwt(token))
    };

    const handleDeleteRow = (index, arrayHelpers) => {
        arrayHelpers.remove(index);
    };

    const getCallByUser = () => {
        let callbyuser = '';

        let calluser = assignToUsers.filter(item => item.id == parseJwt(token)?.id)
        if (calluser?.length > 0) {
            callbyuser = calluser[0]?.firstname
        }
        return callbyuser
    }
    const getCallBy = (callby) => {
        let callbyuser = '';
        if (callby == '') {
            return callbyuser;
        } else if (callby == undefined) {
            let calluser = assignToUsers.filter(item => item.id == parseJwt(token)?.id)
            if (calluser?.length > 0) {
                callbyuser = calluser[0]?.firstname
            }
        } else {
            let calluser = assignToUsers.filter(item => item.firstname.toLowerCase() == callby?.toLowerCase())
            if (calluser?.length > 0) {
                callbyuser = calluser[0]?.firstname
            }
            console.log('callusercalluser', calluser, callby, callbyuser, parseJwt(token))

            return callbyuser
        }
    }
    const getTime = (time) => {
        let formatedTime = ''
        if (time != undefined && time != '') {
            if (typeof time === 'object') {
                formatedTime = time

            } else if (typeof time === 'string') {
                // console.log('timetimetime', time)
                if (time?.includes('T')) {
                    let tempdatetime = time?.split('T')
                    let temptime = tempdatetime[1]?.split('.')
                    time = temptime[0]
                }

                let timeArray = time?.split(':')
                formatedTime = dayjs().set('hour', timeArray[0]).set('minute', timeArray[1]).set('second', timeArray[2])
                // console.log('timetimetime', time, formatedTime, timeArray)
            }
        } // Set the desired time
        return formatedTime;
    }

    const getDateValue = (datevalue) => {
        let finalDate = null;
        if (datevalue != undefined || datevalue != '') {
            if (typeof datevalue === 'string' && datevalue?.length == 13) {
                finalDate = dayjs(parseInt(datevalue))
            } else if (typeof datevalue === 'object') {
                finalDate = datevalue
            }
        }
        return finalDate;
    }

    return (
        // <Grid container item spacing={2} xs={12} m={1}>
        //     <Grid item xs={12}>
        //         <FormLabel>
        //             <Typography px={1} variant="h4" style={{ backgroundColor: '#bacee7', color: '#000000' }}>
        //                 Existing Policy Information
        //             </Typography>
        //         </FormLabel>
        //     </Grid>
        <Accordion defaultExpanded={true} style={{ width: '100%' }}>
            <AccordionSummary
                expandIcon={<ArrowDropUpIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                style={{ backgroundColor: '#bacee7', color: '#000000' }}

            >
                <Typography variant="h5">Calling Track</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0px' }}>
                <Grid container spacing={1} xs={12}  >
                    <FieldArray
                        name="leadsCalls"
                        render={(arrayHelpers) => (
                            <>
                                {values?.leadsCalls?.map((Callinginfo, index) => (
                                    <React.Fragment key={index}>
                                        <Grid container item spacing={2} xs={12} m={1} direction="row">
                                            <Grid item xs={1.2} sm={1} container justifyContent="center" alignItems="center">
                                                <FormLabel component="legend" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    Call No. {index + 1}
                                                </FormLabel>
                                            </Grid>
                                            <Grid item xs={1.8}>
                                                <Stack>
                                                    <FormLabel>Date</FormLabel>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <Field name={`leadsCalls.${index}.date`}>

                                                            {({ field }) => (

                                                                <DatePicker
                                                                    name={`leadsCalls.${index}.date`}
                                                                    onChange={(date) => field.onChange({ target: { value: date, name: field.name } })}
                                                                    // value={dayjs(field.value) || null}
                                                                    // value={(field?.value && dayjs(parseInt(field?.value))) || dayjs(new Date())}
                                                                    value={getDateValue(field?.value)}

                                                                />
                                                            )}
                                                        </Field>

                                                    </LocalizationProvider>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={1.8}>
                                                <Stack>
                                                    <FormLabel>Time</FormLabel>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <Field name={`leadsCalls.${index}.time`}>

                                                            {({ field }) => (

                                                                <TimePicker
                                                                    name={`leadsCalls.${index}.time`}
                                                                    onChange={(date) => field.onChange({ target: { value: date, name: field.name } })}
                                                                    value={(field.value && getTime(field.value)) || dayjs().set('second', 0).set('millisecond', 0)}
                                                                // value={dayjs().set('second', 0).set('millisecond', 0)}
                                                                />
                                                            )}
                                                        </Field>

                                                    </LocalizationProvider>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={1.8}>
                                                <FormControl style={{ width: '100%' }}>
                                                    <FormLabel>Call Status</FormLabel>
                                                    <Select
                                                        name={`leadsCalls.${index}.status`}
                                                        onChange={handleChange}
                                                        value={Callinginfo.status || ''}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        {DataBucket.SALES_CALL_STATUS.map((carrierItem, key) => (
                                                            <MenuItem key={key} value={carrierItem}>
                                                                {carrierItem}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={1.8}>
                                                <Stack>
                                                    <FormLabel>Spoke with</FormLabel>
                                                    <TextField
                                                        name={`leadsCalls.${index}.spokeWith`}
                                                        onChange={handleChange}
                                                        // value={policy.policyNumber || ''}
                                                        value={Callinginfo?.spokeWith}
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={1.8}>
                                                <FormControl style={{ width: '100%' }}>
                                                    <FormLabel>Call By</FormLabel>
                                                    <Select
                                                        name={`leadsCalls.${index}.callby`}
                                                        onChange={handleChange}
                                                        // value={(Callinginfo.callby && getCallBy(Callinginfo.callby)) || getCallByUser()}
                                                        value={getBy(Callinginfo?.callby)}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        {/* {((values.processType == 'Policy Quoting' && values.department == 'Commercial Lines') ? DataBucket.PROPERTY_TYPE_CONFIG_POLICY_QUOTE : DataBucket.PROPERTY_TYPE_CONFIG).map((propertyType, key) => (
                                                            <MenuItem key={key} value={propertyType}>
                                                                {propertyType}
                                                            </MenuItem>
                                                        ))} */}
                                                        {assignToUsers.map((user, key) => (
                                                            <MenuItem key={key} value={user?.firstname + " " + user?.lastname}>
                                                                {user?.firstname + " " + user?.lastname}
                                                            </MenuItem>
                                                        ))}


                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={1.8}>
                                                <Stack>
                                                    <FormControlLabel sx={{ mt: 2 }} value="true" control={<Switch color="success" onChange={handleChange} name={`leadsCalls.${index}.emailSent`} checked={Callinginfo?.emailSent} />} label="Email Sent" labelPlacement="start" componentsProps={{ typography: { variant: 'h5', color: '#8c8c8c' } }} />

                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack>
                                                    <FormLabel>Remarks</FormLabel>
                                                    <TextField
                                                        name={`leadsCalls.${index}.remarks`}
                                                        onChange={handleChange}
                                                        // type="number"
                                                        value={Callinginfo?.remarks}
                                                        // value={policy.currentPremium || ''}
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                    />
                                                </Stack>
                                            </Grid>

                                            {/* <Grid item xs={1} container justifyContent="start" alignItems="flex-end">
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => handleDeleteRow(index, arrayHelpers)}
                                                >
                                                    Delete
                                                </Button>
                                            </Grid> */}
                                            <Grid container item spacing={2} xs={12} m={1} direction="row">
                                                <Divider sx={{ background: '#ccc', height: '1px', width: '100%' }} />
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                ))}
                                <Grid item xs={12} m={2} container justifyContent="flex-end">

                                    <Button variant="outlined" style={{ backgroundColor: '#084E81', color: 'white' }} onClick={() => handleAddRow(arrayHelpers)}>
                                        Add Row
                                    </Button>
                                </Grid>

                            </>
                        )}
                    />

                </Grid>
                <Grid item xs={12} p={2} backgroundColor='#f0f5fc'>
                    <FormLabel>Calling Comments</FormLabel>

                    <OutlinedInput
                        label="Multiline"
                        multiline
                        onChange={handleChange}
                        name="callingComments"
                        fullWidth
                        rows={2}
                        value={values.callingComments || ''}
                    />
                </Grid>
            </AccordionDetails>

        </Accordion>
    );
});

export default CallingTrack;

