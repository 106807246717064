import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const token = localStorage.getItem('token');
    const routes = token ? MainRoutes : LoginRoutes;
    return useRoutes([routes]);
}
