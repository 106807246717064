import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import Store from 'store/ContextStore/Store';
import SaleDetails from 'pages/Sales/SaleDetails';
import { Navigate } from 'react-router-dom';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - report
const Reports = Loadable(lazy(() => import('../pages/Report/index')));

// render - requests
const Requests = Loadable(lazy(() => import('../pages/Requests/index')));

// render - Employees
const Employees = Loadable(lazy(() => import('../pages/Employees/index')));

// render - Clients
const Clients = Loadable(lazy(() => import('../pages/Clients/index')));

// render - Sales

const Sales = Loadable(lazy(() => import('../pages/Sales/index')));

// render - requestsdetails
const RequestDetails = Loadable(lazy(() => import('../pages/Requests/RequestDetails')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

const CustomiseRequestOptions = Loadable(lazy(() => import('../pages/CustomiseRequestOptions/index')));


// ==============================|| MAIN ROUTING ||============================== //
// let isManager = true;

const token = localStorage.getItem('token');

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};
// let isManager = (parseJwt(token)?.role == 'manager') || false;
let isManager = true;
// console.log('isManager', isManager)
let role = parseJwt(token)?.role;
let department = parseJwt(token)?.department;

let userRoutes = department == 'Operations' ? [
    {
        path: '*',
        element: <Navigate to="requests" />
    },
    {
        path: '/',
        element: <Navigate to="requests" />
    },
    {
        path: 'requests',
        element: <Store><Requests /></Store>
    },
    {
        path: 'requests/:id',
        element: <Store><Requests /></Store>
    },
] : department == 'Sales' ? [
    {
        path: '*',
        element: <Navigate to="sales" />
    },
    {
        path: 'Sales',
        element: <Sales />

    },
    {
        path: '/',
        element: <Navigate to="sales" />
    },
    {
        path: 'Sales/:id',
        element: <SaleDetails />
    },
] : [ {
    path: '*',
    element: <Navigate to="requests" />
},
{
    path: '/',
    element: <Navigate to="requests" />
},
{
    path: 'requests',
    element: <Store><Requests /></Store>
},
{
    path: 'requests/:id',
    element: <Store><Requests /></Store>
},
{
    path: 'Sales',
    element: <Sales />

},
{
    path: 'Sales/:id',
    element: <SaleDetails />
},
];

let AdminRoutes = department == 'Operations' ? [
    {
        path: '*',
        element: <Navigate to="requests" />
    },
    {
        path: '/',
        element: <Navigate to="requests" />
    },
    {
        path: 'requests',
        element: <Store><Requests /></Store>
    },
    {
        path: 'requests/:id',
        element: <Store><Requests /></Store>
    },
    {
        path: 'report',
        element: <Store><Reports /></Store>
    },
] : department == 'Sales' ? [
    {
        path: '*',
        element: <Navigate to="sales" />
    },
    {
        path: '/',
        element: <Navigate to="sales" />
    },
    {
        path: 'Sales',
        element: <Sales />
    },
    {
        path: 'Sales/:id',
        element: <SaleDetails />
    },
] : [ {
    path: '*',
    element: <Navigate to="requests" />
},
{
    path: '/',
    element: <Navigate to="requests" />
},
{
    path: 'requests',
    element: <Store><Requests /></Store>
},
{
    path: 'requests/:id',
    element: <Store><Requests /></Store>
},
{
    path: 'Sales',
    element: <Sales />

},
{
    path: 'Sales/:id',
    element: <SaleDetails />
},
{
    path: 'report',
    element: <Store><Reports /></Store>
},
];

let superAdminRoutes = [ {
    path: '*',
    element: <Navigate to="requests" />
},
{
    path: '/',
    element: <Navigate to="requests" />
},
{
    path: 'requests',
    element: <Store><Requests /></Store>
},
{
    path: 'requests/:id',
    element: <Store><Requests /></Store>
},
{
    path: 'employees',
    element: <Employees />
},
{
    path: 'clients',
    element: <Clients />
},
{
    path: 'Sales',
    element: <Sales />

},
{
    path: 'Sales/:id',
    element: <SaleDetails />
},
{
    path: 'report',
    element: <Store><Reports /></Store>
},
{
    path: 'CustomiseRequestOptions',
    element: <Store><CustomiseRequestOptions /></Store>
},
];

let routes = role == 'User' ? userRoutes : role == 'Admin' ? AdminRoutes : superAdminRoutes;
const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        ...routes,
        {
            path: 'color',
            element: <Color />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        },
        {
            path: 'shadow',
            element: <Shadow />
        },
        {
            path: 'typography',
            element: <Typography />
        },
        {
            path: 'icons/ant',
            element: <AntIcons />
        },

    ]
};

export default MainRoutes;
