import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { useQuery, useMutation } from '@apollo/client';
import { GET_ALLWORKHISTORY_BY_ID, CREATE_ATTACHEMNTS, GET_ALLATTACHMENTS_BY_ID, GET_ALL_MODIFIED_ATTACHMENTS_BY_ID, UPLOAD_FILE, UPLOAD_FILES_MUTATION, UPDATE_ATTACHMENTS, DELETE_ATTACHMENTS, GET_ALLATTACHMENT_HISTORY_BY_ID } from "../../Queries/Orders";

import { GET_ALL_USERS } from 'Queries/Users.js';

import axios from 'axios';
import { SERVER_URL } from 'databucket';

export default function AttachemntsHistory(props) {

    let employeeDetails = props.employeeDetails;
    let setEmployeeDetails = props.setEmployeeDetails;
    const { SalesInfo, assignToUsers } = props;
    const fileInputRef = useRef(null);
    const fileInputRefSingle = useRef(null);

    const [attachmentStatus, setAttachmentStatus] = useState(false)

    const [attachmentsHistory, setAttachmentsHistory] = useState([])
    const [modifiedAttachmentsHistory, setModifiedAttachmentsHistory] = useState([])


    const token = localStorage.getItem('token');

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };


    // const { data, } = useQuery(GET_ALL_USERS);

    // let userscontent = data ? data.getAllUsers : [];

    const [createAttachments, { loading }] = useMutation(CREATE_ATTACHEMNTS, {
        onCompleted(data) {
            setAttachmentStatus(!attachmentStatus);
            // console.log(data, 'dataidddd', data.createWorkHistory.id, data.createWorkHistory._id)
        },
    });
    const [deleteAttachments, { }] = useMutation(DELETE_ATTACHMENTS, {
        onCompleted(data) {
            setAttachmentStatus(!attachmentStatus);

            // console.log('deleteAttachments-->', data)
        },
    });



    // const [uploadFile, { }] = useMutation(UPLOAD_FILE, {
    //     onCompleted(data) {

    //         console.log("FILE----------->", data)
    //     },
    // });

    // const [uploadFiles, { }] = useMutation(UPLOAD_FILES_MUTATION, {
    //     onCompleted(data) {

    //         console.log("FILESss----------->", data)
    //     },
    // });


    const attachmentObj = {
        emailId: SalesInfo?.id,
    }
    const attachmentHistoryObj = {
        userId: parseJwt(token)?.id,
        emailId: SalesInfo?.id,
    }
    const [updateAttachments, { }] = useMutation(UPDATE_ATTACHMENTS, {
        onCompleted(data) {
            setAttachmentStatus(!attachmentStatus);
            console.log('updateAttachments-->', data)
        },
    });
    const [getAllAttachmentsByID, { }] = useMutation(GET_ALLATTACHMENTS_BY_ID, {
        onCompleted(data) {
            console.log('getAllAttachmentsByID-->', data)
            setAttachmentsHistory(data.getAllAttachmentsByID)
        },
    });

    // const [getAllModifiedAttachmentsByID, { }] = useMutation(GET_ALL_MODIFIED_ATTACHMENTS_BY_ID, {
    //     onCompleted(data) {
    //         console.log('getAllModifiedAttachmentsByID-->', data)
    //         // generateAttachmentHistory(data.getAllModifiedAttachmentsByID)
    //         setModifiedAttachmentsHistory(data.getAllModifiedAttachmentsByID)
    //     },
    // });

    const [getAllAttachmentHistoryByID, { }] = useMutation(GET_ALLATTACHMENT_HISTORY_BY_ID, {
        onCompleted(data) {
            console.log('getAllAttachmentHistoryByID-->', data)
            // generateAttachmentHistory(data.getAllModifiedAttachmentsByID)
            setModifiedAttachmentsHistory(data.getAllAttachmentHistoryByID)
        },
    });



    useEffect(() => {
        getAllAttachmentsByID({ variables: attachmentObj });
        // getAllModifiedAttachmentsByID({ variables: attachmentObj })
        getAllAttachmentHistoryByID({ variables: attachmentHistoryObj })

    }, [attachmentStatus]);

    const openFileInputSingle = () => {
        if (fileInputRefSingle.current) {
            fileInputRefSingle.current.value = null;
            fileInputRefSingle.current.click();
        }
    };

    // const generateAttachmentHistory = (attachMentHistory) => {
    //     let finalAttachmentHistory = []
    //     let attachMentHistoryTemp = []
    //     attachMentHistoryTemp.push(...attachMentHistory);
    //     attachMentHistoryTemp?.map((attachemnts) => {
    //         let attachemntsTemp = attachemnts;
    //         attachemntsTemp.status = 'Created'
    //         attachemntsTemp.updateDate = attachemnts.addDate
    //         finalAttachmentHistory.push(attachemntsTemp)
    //     })
    //     attachMentHistoryTemp?.map((attachemnts) => {
    //         let attachemntsTemp = attachemnts;
    //         console.log('attachMentHistoryattachMentHistory', attachMentHistoryTemp,)

    //         if (attachemnts.status) {
    //             if (attachemnts.addDate != attachemnts.updateDate) {

    //                 attachemntsTemp.status = 'Updated'
    //                 finalAttachmentHistory.push(attachemntsTemp)
    //             }
    //         } else {
    //             attachemntsTemp.status = 'Deleted'
    //             finalAttachmentHistory.push(attachemntsTemp)
    //         }

    //     })

    //     console.log('attachMentHistoryattachMentHistory', attachMentHistory, attachMentHistoryTemp, finalAttachmentHistory)

    //     return finalAttachmentHistory;
    // }

    const handleSingleAttachment = (event, index) => {

        // console.log("-----------------------")
        const selectedFile = event.target.files[0];

        const formData = new FormData();
        formData.append('files', selectedFile);

        axios.post(SERVER_URL + '/upload',
            formData)
            .then((res) => {


                console.log(res.data);
                let savedFile = res.data.files[0];

                const valuesObject = {
                    userId: parseJwt(token)?.id,
                    emailId: SalesInfo.id,

                    data: savedFile.url,
                    filename: savedFile.originalName,
                    mimetype: savedFile.mimeType,
                    addDate: Date.now().toString(),
                    updateDate: Date.now().toString(),
                    status: true,
                }

                // console.log('handleSingleAttachment-->', valuesObject)

                createAttachments({ variables: valuesObject })

            })
            .catch((error) => {
                console.error(error);
            });

        setEmployeeDetails({ ...employeeDetails, ['Attachment']: event.target.files[0] })
    };


    const openFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
            fileInputRef.current.click();
        }
    };

    const handleMultipleAttachment = (event, index) => {
        console.log('---------------------------->>>>>>>>>')
        const multipleFiles = Array.from(event.target.files);
        const formData = new FormData();
        multipleFiles.forEach((file) => {
            formData.append('files', file);
        });


        axios.post(SERVER_URL + '/upload', formData)
            .then((res) => {
                console.log(res.data);

                let uploadedFiles = res.data.files
                uploadedFiles.map((uploadedItem) => {

                    const valuesObject = {
                        userId: parseJwt(token)?.id,
                        emailId: SalesInfo.id,

                        data: uploadedItem.url,
                        filename: uploadedItem.originalName,
                        mimetype: uploadedItem.mimeType,
                        addDate: Date.now().toString(),
                        updateDate: Date.now().toString(),
                        status: true,
                    }

                    // console.log('handleSingleAttachment-->', valuesObject)

                    createAttachments({ variables: valuesObject })
                })

            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleupadteAttachment = (event) => {
        return event.target.files[0];
    }
    const updateHandler = async (attachemntId) => {
        const selectedFilePromise = new Promise((resolve, reject) => {
            const inputElement = document.getElementById('updateAttachmentInput');
            inputElement.addEventListener('change', (event) => {
                const selectedFile = event.target.files[0];
                resolve(selectedFile);
            });
            inputElement.click();
        });

        const selectedFile = await selectedFilePromise;


        const formData = new FormData();
        formData.append('files', selectedFile);

        axios.post(SERVER_URL + '/upload',
            formData)
            .then((res) => {
                let savedFile = res.data.files[0];

                const valuesObject = {
                    id: attachemntId,
                    data: savedFile.url,
                    filename: savedFile.originalName,
                    mimetype: savedFile.mimeType,
                    updateDate: Date.now().toString(),
                }

                updateAttachments({ variables: valuesObject })

            })

    }

    const deleteHandler = (attachemntId) => {
        const valuesObject = {
            id: attachemntId,
            updateDate: Date.now().toString(),
        }
        console.log(valuesObject, Date.now().toString())
        deleteAttachments({ variables: valuesObject })
    }


    const handleDownload = (fileUrl, fileName) => {
        // console.log('handleDownload-->', fileUrl, fileName)

        fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName; // Set desired file name
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => console.error('Error downloading file:', error));
    };


    // const handleMultipleAttachmentOLD = async (event, index) => {
    //     try {
    //         const selectedFiles = Array.from(event.target.files);
    //         const files = await Promise.all(selectedFiles.map((file) => file));

    //         const { data } = await uploadFile({ variables: { files } });

    //         console.log(data);


    //     } catch (error) {
    //         console.error(error);
    //         // Handle error
    //     }
    //     // setEmployeeDetails({ ...employeeDetails, ['Attachments']: [event.target.files[0].name] })
    //     // setEmployeeDetails({ ...employeeDetails, ['Attachments']: multipleFiles })
    // };

    const getDateTime = (timeValue) => {
        const startDate = new Date(timeValue).toLocaleDateString();
        const startTimeString = new Date(timeValue).toLocaleTimeString(undefined, { hour12: true });

        const dateTimeString = `${startDate} ${startTimeString}`;

        return dateTimeString;
    }

    const getStatus = (status, addDate, updateDate) => {
        let resultData = ""
        if (status) {
            if (addDate == updateDate) {
                resultData = "Created"
            } else {
                resultData = "Updated"
            }
        } else {
            resultData = "Deleted"
        }
        return resultData;
    }
    const getHistoryStatus = (status) => {
        let resultData = ""
        if (status == true) {
            resultData = "Created"
        } else if (status == null) {
            resultData = "Updated"
        } else if (status == false) {
            resultData = "Deleted"
        } else {
            resultData = ""
        }
        return resultData;
    }

    const getUserName = (userid) => {
        let userName = 'anonymous'
        assignToUsers?.map((userdetails) => {
            if (userdetails?.id == userid) {
                userName = userdetails?.firstname
            }
        })
        return userName;
    }

    const getNewUrl = (url) => {
        const initialPart1 = url.split('/uploads')[0];
        const initialPart2 = SERVER_URL;
        let replacedUrl = url
        if (initialPart1 !== initialPart2) {
            replacedUrl = initialPart2 + url.substring(initialPart1.length);
        }
        return replacedUrl;
    }

    return (
        <div>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ArrowDropUpIcon />}
                    // expandIcon={expanded ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: '#bacee7', color: '#000000' }}
                >
                    <Typography variant="h5" >Attachments</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '0px', align: 'center' }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Attachments</TableCell>
                                    <TableCell align="center"> </TableCell>
                                    <TableCell align="center">Add Date</TableCell>
                                    <TableCell align="center">User Name</TableCell>
                                    <TableCell align="center"> </TableCell>
                                    <TableCell align="center"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {attachmentsHistory.map((row, key) => (
                                    <TableRow
                                        key={key}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <a href={getNewUrl(row.data)} target="_blank" >{row.filename}</a>
                                        </TableCell>
                                        <TableCell align="center"><Button
                                            variant="contained"
                                            startIcon={<CloudDownloadIcon />}
                                            onClick={() => handleDownload(getNewUrl(row.data), row.filename)}
                                        >Download</Button></TableCell>
                                        <TableCell align="center">{getDateTime(parseInt(row.addDate))}</TableCell>
                                        <TableCell align="center">{getUserName(row.userId)}</TableCell>
                                        <input type="file" id="updateAttachmentInput" onChange={handleupadteAttachment} style={{ display: 'none' }} />

                                        <TableCell align="center"><Button variant="contained" onClick={() => updateHandler(row.id)}>Replace</Button></TableCell>
                                        <TableCell align="center"><Button variant="contained" onClick={() => deleteHandler(row.id)}>Delete</Button></TableCell>
                                    </TableRow>
                                ))}


                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Stack spacing={2} direction="row" style={{ justifyContent: 'center', width: '100%', margin: '10px' }}>
                        {/* <input type="file" onChange={handleMultipleAttachment} style={{ display: 'none' }} />
                        <Button variant="contained" onClick={() => document.querySelector('input[type="file"]').click()}>Add Attachemnt</Button>
                        <input type="file" multiple onChange={handleMultipleAttachment} style={{ display: 'none' }} />
                        <Button variant="outlined" onClick={() => document.querySelector('input[type="file"][multiple]').click()}>Add Multiple Attachments</Button> */}


                        <input type="file" ref={fileInputRefSingle} onChange={handleSingleAttachment} style={{ display: 'none' }} />
                        <Button variant="contained" onClick={openFileInputSingle}>Add Attachemnt</Button>
                        <input type="file" multiple ref={fileInputRef} onChange={handleMultipleAttachment} style={{ display: 'none' }} />
                        <Button variant="outlined" onClick={openFileInput}>Add Multiple Attachments</Button>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ArrowDropUpIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{ backgroundColor: '#bacee7', color: '#000000' }}

                >
                    <Typography variant="h5">Attachments History</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '0px' }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Attachments</TableCell>
                                    <TableCell align="center">Add Date</TableCell>
                                    <TableCell align="center">Update Date</TableCell>
                                    <TableCell align="center">User Name</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {modifiedAttachmentsHistory.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{row.filename}</TableCell>
                                        <TableCell align="center">{getDateTime(parseInt(row.addDate))}</TableCell>
                                        <TableCell align="center">{getDateTime(parseInt(row.updateDate))}</TableCell>
                                        <TableCell align="center">{getUserName(row.userId)}</TableCell>
                                        <TableCell align="center">{getHistoryStatus(row.status, row.addDate, row.updateDate)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>



        </div>
    );
}
