// ** Graphql
import { gql } from '@apollo/client';

export const AUTH_LOGIN = gql`
  mutation ($email: String!, $password: String) {
    authLogin(email: $email, password: $password) {
      token
      status
      message
    }
  }
`;

export const AUTH_RESET_PASSWORD = gql`
  mutation ($email: String!, $password: String!, $token: String!, $isPasswordGenerated: Boolean) {
    createpassword(email: $email, password: $password, token: $token, isPasswordGenerated: $isPasswordGenerated) {
      status
      message
    }
  }
`;


export const GET_ALL_USERS = gql`
  query ($loginUserId: String!) {
    getAllUsers(loginUserId: $loginUserId) {
      status
      message
      response {
        id
        firstname
        lastname
        email
        mobile
        address
        gender
        token
        isPasswordGenerated
        clients
        department
        employeestatus
        role
      }
    }
  }
`;



export const GET_USER_DETAILS = gql`
  mutation ($id: String!) {
    getUserDetails(id: $id) {
      id
      firstname
      lastname
      email
      mobile
      address
      gender
      role
    }
  }
`;

export const GET_ALL_CLIENTS = gql`
  query {
    getAllClients {
      status
      message
      response{
        id
        clientName
        emailId
        mobileNo
        department
        timeZone
        status
      }
    }
  }
`;

export const GET_ALL_INSURANCE_CARRIERS = gql`
  query {
    getAllInsuranceCarriers {
      status
      message
      response{
        id
        carrierName
        status
        userId
      }
    }
  }
`;

export const GET_ALL_CURRENT_CARRIERS = gql`
  query {
    getAllCurrentCarriers {
      status
      message
      response{
        id
        carrierName
        status
        userId
      }
    }
  }
`;