import { useState, useEffect } from 'react';
import MainCard from 'components/MainCard';
import * as React from 'react';
// material-ui


import {
    Stepper,
    Step,
    StepLabel,
    Select,
    SelectChangeEvent,
    FormControl,
    Radio, RadioGroup,
    Box,
    Tab,
    Tabs,
    InputLabel,
    Grid,
    List,
    Button,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Stack,
    TextField,
    FormControlLabel,
    Checkbox,
    Typography,
    Switch,
    FormLabel,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
// import { DatePicker, LocalizationProvider, AdapterDayjs } from '@mui/x-date-pickers';


// third party
import { Formik } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from "react-redux";
import { editOrder } from "../../store/reducers/ordersSlice";
import {
    UPDATE_REQUEST,
    GET_ALL_ORDERS,
    GET_REQUESTS_BY_ID,
    INSERT_ORDERS,
    UPDATE_WORKHISTORY,
    GET_WORKHISTORY,
    GET_WORKHISTORY_BY_ID,
    GET_ALLWORKHISTORY_BY_ID,
    CREATE_STAUS_WORKHISTORY,
} from "../../Queries/Orders";

// import RequestFormFinal from './RequestFormFinal';
import * as DataBucket from '../../databucket';
import { memo } from 'react';
// import RequestInformation from './RequestInformation';
// import RequestForm from './RequestForm';
// import AttachemntsHistory from './AttachmentsHistory';
// import RequestWorkHistory from './RequestWorkHistory';

import { useParams, useNavigate } from 'react-router-dom';
//tab importss
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// import HeaderContent from 'layout/MainLayout/Header/HeaderContent/index';
import SaleInformation from './SaleInformation';
import CallingTrack from './CallingTrack';
import EmailTrack from './EmailTrack';
import LinkedinTrack from './LinkedinTrack';
import SchedulingCall from './SchedulingCall';
import OperationsTeam from './OperationsTeam';
import { GET_ALL_USERS } from 'Queries/Users';
import { GET_USER_DETAILS } from 'Queries/Users';
import { CREATE_LEAD_WORKHISTORY, GET_LEAD_BY_ID, UPDATE_LEAD } from 'Queries/Leads';
import AttachemntsHistory from './AttachmentsHistory';
import RequestWorkHistory from './RequestWorkHistory';

const SaleDetails = memo((props) => {
    // const { SalesInfo } = props;
    const [SalesInfo, setSalesInfo] = useState({});

    const { id } = useParams();

    // console.log("SalesInfoSalesInfo-->", SalesInfo)

    // const [instructionVal, setInstructionVal] = useState(RequestInfo.instructions ? RequestInfo.instructions : false);
    // const [fromPendingVal, setfromPendingVal] = useState(RequestInfo.fromPending ? RequestInfo.fromPending : false);
    const dispatch = useDispatch();

    const [age, setAge] = useState('');
    const [value, setValue] = useState('');
    const [employeeDetails, setEmployeeDetails] = React.useState({});

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const initialTime = 0;
    const [elapsedTime, setElapsedTime] = useState(0);
    const [workHistoryId, setWorkHistoryId] = useState('');
    const [startTime, setStartTime] = useState('');
    const [isRunning, setIsRunning] = useState(false);
    let intervalId = null;

    const [tabValue, setTabValue] = useState(0);
    // const { handleStop: mainTimerStop } = HeaderContent();

    // const headerContent = HeaderContent();
    const [assignToUsers, setAssignToUsers] = useState([]);


    // const { handleStop: mainTimerStop } = headerContent;
    const tabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const navigate = useNavigate();


    // console.log("RequestInfo-->", RequestInfo, RequestInfo.date)
    // const workHistoryObj = {
    //     emailId: RequestInfo.id,
    // }
    const token = localStorage.getItem('token');

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    const userid = parseJwt(token).id;
    console.log("userid",userid)

    const [updateWorkHistory, { }] = useMutation(UPDATE_WORKHISTORY, {
        onCompleted(data) {
            // console.log(data, 'data')
            setIsRunning(false);
        },
    });


    const [getLeadById, { }] = useMutation(GET_LEAD_BY_ID, {
        onCompleted(data) {
            setSalesInfo(data?.getLeadById[0])
            // console.log(data, 'dataaaaa')


        },
    });

    const [getWorkHistoryID, { }] = useMutation(GET_WORKHISTORY_BY_ID, {
        onCompleted(data) {
            let datacontent = data.getWorkHistoryID;
            if (datacontent.length > 0) {
                setWorkHistoryId(datacontent[0].id)
                setStartTime(datacontent[0].startTime)
                setIsRunning(true);
            }
        },
    });

    const [createLeadWorkHistory, { }] = useMutation(CREATE_LEAD_WORKHISTORY, {
        onCompleted(data) {
            // console.log(data.createLeadWorkHistory.id, 'data')

        },
    });

    useEffect(() => {
        // getWorkHistoryID({ variables: workHistoryObj });
        const valuesObject = {
            id: id,
        }
        getLeadById({ variables: valuesObject })
    }, []);

    const { data, } = useQuery(GET_ALL_USERS,{
        variables: { loginUserId:userid },
      });

    let userscontent = data ? data.getAllUsers : [];

    useEffect(() => {

        // console.log("userscontent", userscontent)

        if (userscontent.status == 200) {
            if (userscontent.response?.length > 0) {
                const usersList=userscontent.response

                // const user=usersList.find(user => user.id === userid);
                
                // const userDepartment=user?.department
               
                // const filteredUserList=usersList.filter(item => {
                //     if (userDepartment === "Operations") {
                //         return item.department === "Operations" || item.department==="Both";
                //     }
                //     else if (userDepartment === "Sales") {
                //         return item.department === "Sales" || item.department==="Both";
                //     }
                //     return true;
                // })

                // setAssignToUsers(filteredUserList)
                setAssignToUsers(usersList)
                console.log("filtered data",usersList)

            }
        }

    }, [userscontent]);

    

    useEffect(() => {
        let intervalId;
        if (isRunning) {
            intervalId = setInterval(() => {
                const currentTime = new Date().getTime();
                const timeDifference = currentTime - parseInt(startTime);
                setElapsedTime(timeDifference);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [isRunning, startTime]);
    // const { data, } = useQuery(GET_WORKHISTORY);

    // const datacontent = data ? data.getAllWorkHistory : [];


    const formatTime = (time) => {
        const seconds = Math.floor(time / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(months / 12);

        const formattedTime = [
            years > 0 ? `${years} year${years > 1 ? 's' : ''}` : '',
            months > 0 ? `${months} month${months > 1 ? 's' : ''}` : '',
            days > 0 ? `${days} day${days > 1 ? 's' : ''}` : '',
            `${hours % 24}:${minutes % 60}:${seconds % 60}`,
        ].filter(Boolean).join(' ');

        return formattedTime;
    };

    // const handleStart = () => {
    //     setIsRunning(true);
    //     setStartTime(Date.now().toString())
    //     mainTimerStop();
    // };

    // const handleStop = () => {
    //     setIsRunning(false);
    //     setElapsedTime(initialTime);

    //     const valuesObject = {
    //         id: workHistoryId,

    //         endTime: Date.now().toString(),
    //     }
    //     updateWorkHistory({ variables: valuesObject });
    // };



    const steps = ['Request Details', 'Attachment History', 'Work History'];


    const isStepOptional = (step) => {
        return step === 1; // set optional stage
    };




    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const cancelHandelar = () => {
        navigate('/sales')

        // console.log("workHistoryDetails22", workHistoryDetails)

    }


    // React.useEffect(() => {
    //     employeeDetails.QuoteType = RequestInfo.quoteType;
    //     setEmployeeDetails({ ...employeeDetails, 'QuoteType': RequestInfo.quoteType })

    // }, []);

    // const initialValues = {
    //     from: RequestInfo.from,
    //     to: RequestInfo.to,
    //     requestId: RequestInfo.requestId,
    //     subject: RequestInfo.subject,
    //     emailNeeded: RequestInfo.emailNeeded,
    //     emailCompleted: RequestInfo.emailCompleted,
    //     hold: RequestInfo.hold,
    //     date: RequestInfo.date,
    //     taskComplete: RequestInfo.taskComplete,
    //     priority: RequestInfo.priority,
    //     Assign: RequestInfo.Assign,
    //     request_type: RequestInfo.request_type,
    //     instructions: instructionVal,
    //     fromPending: fromPendingVal,
    //     quoteType: RequestInfo.quoteType,
    //     department: RequestInfo.department,
    //     insuredName: RequestInfo.insuredName,
    //     noofQuotes: RequestInfo.noofQuotes,
    //     holderName: RequestInfo.holderName,
    //     insuredDelivery: RequestInfo.insuredDelivery,
    //     holderDelivery: RequestInfo.holderDelivery,
    //     accountManagerDelivery: RequestInfo.accountManagerDelivery,
    //     otherDelivery: RequestInfo.otherDelivery,
    //     radioValue: RequestInfo.radioValue,
    //     Auto: RequestInfo.Auto || false,
    //     MotorCycle: RequestInfo.MotorCycle || false,
    //     Boat: RequestInfo.Boat || false,
    //     DP3: RequestInfo.DP3 || false,
    //     HO3: RequestInfo.HO3 || false,
    //     HO4: RequestInfo.HO4 || false,
    //     HO5: RequestInfo.HO5 || false,
    //     HO6: RequestInfo.HO6 || false,
    //     comments: RequestInfo.comments,
    //     // priority: RequestInfo.priority,
    //     processType: RequestInfo.processType,
    //     assignedTo: RequestInfo.assignedTo,
    //     assignTo: RequestInfo.assignTo,
    //     startDate: RequestInfo.startDate,
    //     dueDate: RequestInfo.dueDate,
    //     completedDate: RequestInfo.completedDate,
    //     requestStatus: RequestInfo.requestStatus,
    //     radioInfo: RequestInfo.radioInfo,
    //     emailSummary: RequestInfo.emailSummary,
    //     requestor: RequestInfo.requestor,
    //     sendTo: RequestInfo.sendTo,
    //     newQuoteInformation: (RequestInfo.newQuoteInformation.length > 0) ? RequestInfo.newQuoteInformation : [{
    //         policy: '',
    //         insuranceCarrier: '',
    //         eligibility: '',
    //         quotedPremium: '',
    //         remarks: '',

    //     }],
    //     existingPolicyInformation: (RequestInfo.existingPolicyInformation.length > 0) ? RequestInfo.existingPolicyInformation : [{
    //         currentCarrier: '',
    //         propertyType: '',
    //         propertyNew: '',
    //         policyNumber: '',
    //         effectiveDate: null,
    //         currentPremium: ''
    //     }]
    //     // newQuoteInformation: [{ policy: '', insuranceCarrier: '', quotedPremium: '' }]
    //     // attachmentsURLs: RequestInfo.attachmentsURLs,


    // }
    // console.log(RequestInfo, 'RequestInfo', RequestInfo.id)
    const initialValues = {
        id: SalesInfo?.id,
        leadID: SalesInfo?.leadID,
        createdDate: SalesInfo?.createdDate,
        createdBy: SalesInfo?.createdBy,
        lastUpdatedDate: SalesInfo?.lastUpdatedDate,
        lastContactedDate: SalesInfo?.lastContactedDate,
        followup: SalesInfo?.followup,
        followupDate: SalesInfo?.followupDate,
        callscheduledwithOPs: SalesInfo?.callscheduledwithOPs,
        assign: SalesInfo?.assign,
        assignedTo: SalesInfo?.assignedTo,
        assignTo: SalesInfo?.assignTo,
        priority: SalesInfo?.priority,
        status: SalesInfo?.status,
        stage: SalesInfo?.stage,
        channel: SalesInfo?.channel,
        successfull: SalesInfo?.successfull,
        agencyName: SalesInfo?.agencyName || '',
        address: SalesInfo?.address,
        city: SalesInfo?.city,
        state: SalesInfo?.state,
        timeZone: SalesInfo?.timeZone,
        webURL: SalesInfo?.webURL,
        contactInfoURL: SalesInfo?.contactInfoURL,
        linkedinURL: SalesInfo?.linkedinURL || '',
        contactInfoComments: SalesInfo?.contactInfoComments,
        callingComments: SalesInfo?.callingComments,
        emailComments: SalesInfo?.emailComments,
        linkedinComments: SalesInfo?.linkedinComments,
        scheduledCallComments: SalesInfo?.scheduledCallComments,
        opsTeamComments: SalesInfo?.opsTeamComments,
        leadsContacts: SalesInfo?.leadsContacts,
        leadsCalls: SalesInfo?.leadsCalls,
        leadsEmails: SalesInfo?.leadsEmails,
        leadsLinkedins: SalesInfo?.leadsLinkedins,
        leadsScheduledCalls: SalesInfo?.leadsScheduledCalls,
        leadsOPS: SalesInfo?.leadsOPS,

        // leadsContacts: [{
        //     contactPerson: 'Bhanu',
        //     title: 'BEN',
        //     phone: '9898989898',
        //     email: 'test@test.com',
        //     remarks: 'No',
        //     status: false,
        // }],
        // leadsCalls: [{
        //     date: '01/01/2024',
        //     time: '10:30 AM',
        //     status: 'true',
        //     spokeWith: 'Bhanu',
        //     callby: 'Bhanu',
        //     remarks: 'NO',
        // }],
        // leadsEmails: [{
        //     date: '02/01/2024',
        //     time: '11:55 AM',
        //     status: 'true',
        //     emailedto: 'bhanu@ben.com',
        //     emailedy: 'test@tt.com',
        //     remarks: 'no',
        //     emailFollowup: 'email',
        // }],
        // leadsLinkedins: [{
        //     date: '03/01/2024',
        //     time: '12:20 PM',
        //     status: 'true',
        //     requestTo: 'test',
        //     requestBy: 'test',
        //     remarks: 'NO',
        // }],
        // leadsScheduledCalls: [{
        //     date: '04/01/2024',
        //     time: '01:15 PM',
        //     callType: 'test',
        //     emails: 'yes@yes.com',
        //     phonenumber: '9797979797',
        //     callScheduledBy: 'test',
        //     remarks: 'NO',
        // }],
        // leadsOPS: [{

        //     date: '04/01/2024',
        //     time: '01:15 PM',
        //     callType: 'test',
        //     emails: 'yes@yes.com',
        //     phonenumber: '9797979797',
        //     callScheduledBy: 'test',
        //     remarks: 'NO',
        //     callConnected: true,
        //     clientAttendees: 'test',
        //     blueplanitAttendees: 'test',
        //     amsusedbyClient: 'test',
        //     clientRequirements: 'test',
        //     pros: '5',
        //     cons: '1',
        //     emailSent: true,
        // }],
    }
    // console.log(initialValues, 'initialValues')
    const getTimeZoneCode = (timezone) => {
        // console.log(timezone, 'timezonetimezone')

        if (timezone?.length == 3) {
            return timezone;
        } else {
            switch (timezone) {
                case 'Eastern Time':
                    return "EST";
                case 'Central Time':
                    return "CST";
                case 'Mountain Time':
                    return "MST";
                case 'Pacific Time':
                    return "PST";
                case 'Hawaii Time':
                    return "HST";
            }
        }
    }

    const submit = (values) => {
        // console.log(values, 'valuess')

        const valuesObject = {
            id: SalesInfo.id,
            leadID: values?.leadID,
            createdDate: values?.createdDate,
            createdBy: values?.createdBy,
            lastUpdatedDate: Date.now() + '',
            lastContactedDate: values?.lastContactedDate,
            followup: values?.followup,
            // followupDate: (values?.followup == true && values?.followupDate == null) ? new Date().toISOString() : values?.followupDate,
            followupDate: values?.followupDate,
            callscheduledwithOPs: values?.callscheduledwithOPs,
            assign: values?.assign,
            assignedTo: ((values?.assignTo == null) ? values?.assignedTo : values?.assignTo),
            assignTo: values?.assignTo,
            priority: values?.priority,
            status: values?.status,
            stage: values?.stage,
            channel: values?.channel,
            successfull: values?.successfull,
            agencyName: values.agencyName,
            address: values.address,
            city: values.city,
            state: values.state,
            timeZone: getTimeZoneCode(values?.timeZone),
            webURL: values?.webURL,
            contactInfoURL: values?.contactInfoURL,
            linkedinURL: values?.linkedinURL,
            contactInfoComments: values?.contactInfoComments,
            callingComments: values?.callingComments,
            emailComments: values?.emailComments,
            linkedinComments: values?.linkedinComments,
            scheduledCallComments: values?.scheduledCallComments,
            opsTeamComments: values?.opsTeamComments,
            leadsContacts: values?.leadsContacts.map(({ __typename, ...rest }) => rest),
            leadsCalls: values?.leadsCalls.map(({ __typename, ...rest }) => rest),
            leadsEmails: values?.leadsEmails.map(({ __typename, ...rest }) => rest),
            leadsLinkedins: values?.leadsLinkedins.map(({ __typename, ...rest }) => rest),
            leadsScheduledCalls: values?.leadsScheduledCalls.map(({ __typename, ...rest }) => rest),
            leadsOPS: values?.leadsOPS.map(({ __typename, ...rest }) => rest),
        }


        // const updatedObject = DataBucket.REQUEST_SUBMIT_KEYS.reduce((acc, key) => {
        //     let finalKey = key.charAt(0).toLowerCase() + key.slice(1);
        //     // let finalValue = (employeeDetails[key] != undefined) ? employeeDetails[key] : ""
        //     let finalValue;

        //     if (finalKey == 'existingPolicyInformation') {

        //         const policyNumberKeys = Object.keys(employeeDetails).filter((key) => key.startsWith("Policy_Number_"));
        //         let existingPolicyInformationData = []
        //         for (let count = 1; count <= policyNumberKeys.length; count++) {
        //             let existingPolicyInformationObject;

        //             let Current_Carrier = employeeDetails['Current_Carrier_' + count]
        //             let Property_Type = employeeDetails['Property_Type_' + count]
        //             let Property_New = employeeDetails['Property_New_' + count]
        //             let Policy_Number = employeeDetails['Policy_Number_' + count]
        //             let Effective_Date = employeeDetails['Effective_Date_' + count]
        //             let Current_Premium = employeeDetails['Current_Premium_' + count]
        //             existingPolicyInformationObject = {
        //                 currentCarrier: Current_Carrier,
        //                 propertyType: Property_Type,
        //                 propertyNew: Property_New,
        //                 policyNumber: Policy_Number,
        //                 effectiveDate: Effective_Date,
        //                 currentPremium: parseInt(Current_Premium)
        //             }
        //             existingPolicyInformationData.push(existingPolicyInformationObject)

        //         }

        //         finalValue = existingPolicyInformationData;

        //     } else if (finalKey == 'newQuoteInformation') {

        //         const policyNumberKeys = Object.keys(employeeDetails).filter((key) => key.startsWith("Insurance_Carrier_"));
        //         let newQuoteInformationData = []
        //         for (let count = 1; count <= policyNumberKeys.length; count++) {
        //             let newQuoteInformationObject;

        //             let Policy = employeeDetails['Policy_' + count]
        //             let Insurance_Carrier = employeeDetails['Insurance_Carrier_' + count]
        //             let Quoted_Premium = employeeDetails['Quoted_Premium_' + count]

        //             newQuoteInformationObject = {
        //                 policy: Policy,
        //                 insuranceCarrier: Insurance_Carrier,
        //                 quotedPremium: parseInt(Quoted_Premium),
        //             }
        //             newQuoteInformationData.push(newQuoteInformationObject)
        //         }

        //         finalValue = newQuoteInformationData;

        //         // } else {
        //         //     finalValue = (employeeDetails[key] != undefined) ? employeeDetails[key] : ""
        //     }

        //     return { ...acc, [finalKey]: finalValue };
        // }, {});


        // const workHistoryObj = { workHistory: [workHistoryDetails] } //...workHistoryObj 

        // const mergedObj = { ...valuesObject, ...updatedObject, };

        // if (RequestInfo.radioInfo != values.radioInfo) {


        const historyObject = {
            leadId: SalesInfo.id,
            userName: getBy(undefined),
            leadStatus: 'Updated',
            changeTime: Date.now().toString(),
        }
        createLeadWorkHistory({ variables: historyObject })
        // console.log(valuesObject, 'valuesObject', SalesInfo)
        // updateRequest({ variables: valuesObject });
        updateLead({ variables: valuesObject });
    }

    const [updateLead, { loading, error }] = useMutation(UPDATE_LEAD, {
        onCompleted(data) {
            // dispatch(editOrder(data.updateRequest));
            // props.handleDetailPage(SalesInfo);
            setSetPopupStatus(true);
            let submitResponse = data?.updateLead && "Submitted Successfully!" || 'Error'
            setSubmitStatus(submitResponse)
        },
        onError(error) {
            // Handle error here
            setSetPopupStatus(true);
            // console.error('Mutation error:', error, err);
            // if (error.graphQLErrors && error.graphQLErrors.length > 0) {
            //     const errorMessages = error.graphQLErrors.map((errorObj) => errorObj.message);
            //     console.log('Error Messages:', errorMessages);
            // }
            setSubmitStatus('An error occurred. Please try again.'); // Set your error message

        },
    });


    const [popupStatus, setSetPopupStatus] = useState(false);
    const [submitStatus, setSubmitStatus] = useState('');


    const handleDialogClose = () => {
        setSetPopupStatus(false);
        // navigate('/requests') // requested to stop naviagate
    };
    const getBy = (userby) => {
        // console.log('callbycallby', userby, userby == undefined, userby == null, userby == '')
        let callbyuser = '';
        if (userby == '') {
            return callbyuser;
        } else if (userby == undefined) {
            let calluser = assignToUsers.filter(item => item.id == parseJwt(token)?.id)
            // console.log('callbycallby', calluser, assignToUsers, parseJwt(token)?.id)
            if (calluser?.length > 0) {
                callbyuser = calluser[0]?.firstname + " " + calluser[0]?.lastname
            }
            // console.log('callbycallby', callbyuser)
            return callbyuser
        } else {
            // let calluser = assignToUsers.filter(item => item.firstname.toLowerCase() == userby?.toLowerCase())
            let calluser = assignToUsers.filter(item => (item.firstname.toLowerCase() + " " + item.lastname.toLowerCase()) == userby?.toLowerCase())
            if (calluser?.length == 0) {
                calluser = assignToUsers.filter(item => item.firstname.toLowerCase() == userby?.toLowerCase())
            }
            if (calluser?.length > 0) {
                callbyuser = calluser[0]?.firstname + " " + calluser[0]?.lastname
            }
            // console.log('callusercalluser', calluser, userby, callbyuser, parseJwt(token))

            return callbyuser
        }
    }





    return (
        <>
            <Dialog open={popupStatus} onClose={handleDialogClose}>
                <DialogTitle>Submit Status</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {submitStatus}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>



            <Box sx={{ width: '100%' }}>

                {Object.keys(SalesInfo).length > 0 && <Formik
                    initialValues={initialValues}
                    onSubmit={submit}>
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} mt={2} container justifyContent="flex-end">
                                    <Stack direction="row" spacing={2}>
                                        <Button variant="contained" type="submit">
                                            Submit
                                        </Button>
                                        <Button variant="contained" color="error" onClick={cancelHandelar}>
                                            Close
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>

                            <SaleInformation initialValues={initialValues} employeeDetails={employeeDetails} setEmployeeDetails={setEmployeeDetails} SalesInfo={SalesInfo}  setFieldValue={setFieldValue} />

                            <Tabs centered value={tabValue} onChange={tabChange} variant="fullWidth" style={{ marginBottom: '10px' }} aria-label="Tabs Example">
                                <Tab label="Calling Track" />
                                <Tab label="Email Track" />
                                <Tab label="Linkedin Track" />
                                <Tab label="Scheduling a Call" />
                                <Tab label="Operations Team Communication" />
                                <Tab label="Attachment History" />
                                <Tab label="Work History" />
                            </Tabs>
                            {tabValue === 0 && <CallingTrack employeeDetails={employeeDetails} setEmployeeDetails={setEmployeeDetails} SalesInfo={SalesInfo} assignToUsers={assignToUsers} getBy={getBy} setFieldValue={setFieldValue} />}
                            {tabValue === 1 && <EmailTrack employeeDetails={employeeDetails} setEmployeeDetails={setEmployeeDetails} SalesInfo={SalesInfo} assignToUsers={assignToUsers} getBy={getBy} setFieldValue={setFieldValue} />}
                            {tabValue === 2 && <LinkedinTrack employeeDetails={employeeDetails} setEmployeeDetails={setEmployeeDetails} SalesInfo={SalesInfo} assignToUsers={assignToUsers} getBy={getBy} setFieldValue={setFieldValue} />}
                            {tabValue === 3 && <SchedulingCall employeeDetails={employeeDetails} setEmployeeDetails={setEmployeeDetails} SalesInfo={SalesInfo} assignToUsers={assignToUsers} getBy={getBy} setFieldValue={setFieldValue} />}
                            {tabValue === 4 && <OperationsTeam employeeDetails={employeeDetails} setEmployeeDetails={setEmployeeDetails} SalesInfo={SalesInfo} assignToUsers={assignToUsers} getBy={getBy} setFieldValue={setFieldValue} />}
                            {tabValue === 5 && <AttachemntsHistory initialValues={initialValues} employeeDetails={employeeDetails} setEmployeeDetails={setEmployeeDetails} SalesInfo={SalesInfo} assignToUsers={assignToUsers} />}
                            {tabValue === 6 && <RequestWorkHistory initialValues={initialValues} employeeDetails={employeeDetails} setEmployeeDetails={setEmployeeDetails} SalesInfo={SalesInfo} assignToUsers={assignToUsers} />}

                        </form>
                    )}
                </Formik>}
            </Box>

        </>
    );

})

export default SaleDetails;