import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// render - login
//const AuthLoginNew = Loadable(lazy(() => import('routes/LandingPage')));
// const AuthResetNew = Loadable(lazy(() => import('routes/LandingPageReset')));
const AuthLoginNew = Loadable(lazy(()=>import('pages/authentication/LandingPage.js')));
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthReset = Loadable(lazy(() => import('pages/authentication/ResetPassword')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '',
            // path: 'loginNew',
            element: <AuthLoginNew />
        },
        // {
        //     path: 'login',
        //     element: <AuthLogin />
        // },
        {
            path: 'register',
            element: <AuthRegister />
        },
        // {
        //     path: 'reset',
        //     element: <AuthReset />
        // },
        // {
        //     path: 'reset',
        //     element: <AuthResetNew />
        // }
    ]
};

export default LoginRoutes;
