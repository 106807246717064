// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    openItem: ['dashboard'],
    openComponent: 'buttons',
    drawerOpen: true,
    componentDrawerOpen: true,
    timerHistoryPayload: {},
    taskStartTime : null
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.openItem = action.payload.openItem;
        },

        activeComponent(state, action) {
            state.openComponent = action.payload.openComponent;
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload.drawerOpen;
        },

        openComponentDrawer(state, action) {
            state.componentDrawerOpen = action.payload.componentDrawerOpen;
        },
        timerHistoryPayloadChange(state, action){
            state.timerHistoryPayload = action.payload.data;
        },
        timerPayloadUpdate(state, action){
            state.timerHistoryPayload = {};
        },
        settaskStartTime(state,action){
            state.taskStartTime = action.payload
        }
    }
});

export default menu.reducer;

export const { activeItem, activeComponent, openDrawer, openComponentDrawer, timerHistoryPayloadChange, timerPayloadUpdate,settaskStartTime } = menu.actions;
