// assets
import { DashboardOutlined } from '@ant-design/icons';

// icons
const icons = {
    DashboardOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-dashboard',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'report',
            title: 'Report',
            type: 'item',
            url: '/report',
            icon: icons.DashboardOutlined,
            breadcrumbs: false,
            role: ['Super Admin'],
            department: ['Operations', 'Sales', 'Both']
        },
        {
            id: 'requests',
            title: 'Requests',
            type: 'item',
            url: '/requests',
            icon: icons.DashboardOutlined,
            breadcrumbs: false,
            role: ['Super Admin', 'User', 'Admin'],
            department: ['Operations', 'Both']
        },
        {
            id: 'employees',
            title: 'Employees',
            type: 'item',
            url: '/employees',
            icon: icons.DashboardOutlined,
            breadcrumbs: false,
            role: ['Super Admin', 'Admin'],
            department: ['Operations', 'Sales', 'Both']
        },
        {
            id: 'clients',
            title: 'Clients',
            type: 'item',
            url: '/clients',
            icon: icons.DashboardOutlined,
            breadcrumbs: false,
            role: ['Super Admin', 'Admin'],
            department: ['Operations', 'Sales', 'Both']
        },
        {
            id: 'sales',
            title: 'Sales',
            type: 'item',
            url: '/sales',
            icon: icons.DashboardOutlined,
            breadcrumbs: false,
            role: ['Super Admin', 'User', 'Admin'],
            department: ['Sales', 'Both']
        },
        {
            id: 'CustomiseRequestOptions',
            title: 'Customise Request Options',
            type: 'item',
            url: '/CustomiseRequestOptions',
            icon: icons.DashboardOutlined,
            breadcrumbs: false,
            role: ['Super Admin'],
            department: ['Operations', 'Both']
        },
    ]
};

export default dashboard;
