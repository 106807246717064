import * as React from 'react';
import { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { useQuery, useMutation } from '@apollo/client';
import { GET_ALLWORKHISTORY_BY_ID, GET_ALLSTATUSWORKHISTORY_BY_ID, CREATE_ATTACHEMNTS, GET_ALLATTACHMENTS_BY_ID, UPLOAD_FILE, UPDATE_ATTACHMENTS, DELETE_ATTACHMENTS } from "../../Queries/Orders";

import { GET_USER_DETAILS, GET_ALL_USERS } from 'Queries/Users.js';
import { GET_ALL_LEAD_WORKHISTORY_BY_ID } from 'Queries/Leads';


function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const requestWorkHistory1 = [
    createData('01/01/2023 10:35:00 A.M.', 'Open Request', 'User Name'),
    createData('01/01/2023 10:25:00 A.M.', 'Email', 'User Name'),

];




export default function RequestWorkHistory(props) {

    let employeeDetails = props.employeeDetails;
    let setEmployeeDetails = props.setEmployeeDetails;
    const { SalesInfo, assignToUsers } = props;

    const [workHistoryDetails, setWorkHistoryDetails] = useState([])
    const [leadWorkHistoryDetails, setLeadWorkHistoryDetails] = useState([])



    const workHistoryObj = {
        leadId: SalesInfo?.id,
    }


    // const { data, } = useQuery(GET_ALL_USERS);

    // let userscontent = data ? data.getAllUsers : [];

    // console.log('userscontent', userscontent)

    const [getAllWorkHistoryByID, { }] = useMutation(GET_ALLWORKHISTORY_BY_ID, {
        onCompleted(data) {
            console.log('getAllWorkHistoryByID-->', data)
            setWorkHistoryDetails(data.getAllWorkHistoryByID)
        },
    });

    const [getAllLeadWorkHistoryByID, { }] = useMutation(GET_ALL_LEAD_WORKHISTORY_BY_ID, {
        onCompleted(data) {
            console.log('getAllLeadWorkHistoryByID-->', data)
            setLeadWorkHistoryDetails(data.getAllLeadWorkHistoryByID)
        },
    });


    useEffect(() => {
        // getAllWorkHistoryByID({ variables: workHistoryObj });
        getAllLeadWorkHistoryByID({ variables: workHistoryObj });

    }, []);


    const getDateTime = (timeValue) => {
        const startDate = new Date(timeValue).toLocaleDateString();
        const startTimeString = new Date(timeValue).toLocaleTimeString(undefined, { hour12: true });

        const dateTimeString = `${startDate} ${startTimeString}`;

        return dateTimeString;
    }


    const getUserName = (userid) => {
        let userName = 'anonymous'
        assignToUsers?.map((userdetails) => {
            if (userdetails?.id == userid) {
                userName = userdetails?.firstname
            }
        })
        return userName;
    }




    return (
        <div>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ArrowDropUpIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: '#bacee7', color: '#000000' }}
                >
                    <Typography variant="h5" style={{ padding: '5px' }}>Sales Work History</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '0px' }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" style={{ width: '70%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ padding: '10px' }}>Date & Time</TableCell>
                                    <TableCell style={{ padding: '10px' }} align="center">Status</TableCell>
                                    <TableCell style={{ padding: '10px' }} align="center">UserName</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {leadWorkHistoryDetails.map((row) => (
                                    <TableRow
                                        key={row.changeTime}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell style={{ padding: '10px' }} component="th" scope="row">
                                            {getDateTime(parseInt(row.changeTime))}
                                        </TableCell>
                                        <TableCell style={{ padding: '10px' }} align="center">{row.leadStatus}</TableCell>
                                        <TableCell style={{ padding: '10px' }} align="center">{row.userName}</TableCell>
                                        {/* () => fetchUserDetails( */}
                                    </TableRow>
                                ))}
                                <TableRow
                                    key={SalesInfo.createdDate}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={{ padding: '10px' }} component="th" scope="row">
                                        {getDateTime(parseInt(SalesInfo.createdDate))}
                                    </TableCell>
                                    <TableCell style={{ padding: '10px' }} align="center">Created</TableCell>
                                    <TableCell style={{ padding: '10px' }} align="center">{SalesInfo.createdBy}</TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
            {/* <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ArrowDropUpIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    style={{ backgroundColor: '#bacee7', color: '#000000' }}

                >
                    <Typography variant="h5">Sales Time Records ( {getTotalWorkingHours(workHistoryDetails)} )</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '0px' }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" style={{ width: '80%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Total Time</TableCell>

                                    <TableCell align="center">Start Time</TableCell>
                                    <TableCell align="center">End Time</TableCell>
                                    <TableCell align="center">Task Description</TableCell>
                                    <TableCell align="center">User Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {workHistoryDetails.map((row, key) => (
                                    <TableRow
                                        key={key}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    ><TableCell align="center">
                                            {calculateWorkingHours(parseInt(row.startTime), parseInt(row.endTime))}
                                        </TableCell>
                                        <TableCell component="th" scope="row" align="center">{getDateTime(parseInt(row.startTime))}</TableCell>
                                        <TableCell align="center">{getDateTime(parseInt(row.endTime))}</TableCell>
                                        <TableCell align="center">{row.subject}</TableCell>
                                        <TableCell align="center">{getUserName(row.userId)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion> */}


        </div>
    );
}