// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orders: [],
  reportData: [],
  summary:{},
  TotalCount: 0,
};
const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    getOrders: (state, action) => {
      state.orders = action.payload;
    },
    getReportData: (state,action)=>{
      state.reportData = action.payload;
    },
    getSummaryData: (state,action)=>{
      state.summary = action.payload;
    },
    TotalCount: (state, action) => {
      state.TotalCount = action.payload;
    },
    editOrder: (state, action) => {
      state.orders = state.orders.map((order) =>
      order.id === action.payload.id ? action.payload : order
      );
    },
  },
});

export const { getOrders,getReportData,editOrder,TotalCount, getSummaryData } = ordersSlice.actions;

export default ordersSlice.reducer;
