import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import MainCard from 'components/MainCard';
import { BlueplanitModal, BlueplanitModalTitle } from 'components/BlueplanitModal';
import DialogContent from '@mui/material/DialogContent';
// material-ui
import {
    Select,
    FormControl,
    OutlinedInput,
    InputLabel,
    Grid,
    List,
    Button,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Stack,
    TextField,
    FormControlLabel,
    Checkbox,
    Typography,
    Switch,
    FormLabel
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { Formik } from 'formik';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useQuery, useMutation } from '@apollo/client';
import * as DataBucket from '../../databucket'

import { CREATE_LOGINWORKHISTORY, CREATE_WORKHISTORY, GET_WORKHISTORY, GET_ALL_ORDERS} from "../../Queries/Orders";
import { timerHistoryPayloadChange } from "../../store/reducers/menu";
import { useDispatch, useSelector } from 'react-redux';
import { GET_ALL_CLIENTS} from "../../Queries/Users";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
};

const LoginWorkHistory = (props) => {
    const { open, handleClose, handleStart, RequestInfo, setLoginWorkHistoryId, setWorkHistoryId, handleStop,setistaskRunning,settask } = props;


    const dispatch = useDispatch();

    const initialValues = {

        userId: '',
        title: '',
        description: '',
        companyname: 'Blueplaint',
        department: '',
        task: '',
        serviceType: '',
        email: '',
        subject: '',
        body: '',
        requestId: ''
    }


    const orders  = useQuery(GET_ALL_ORDERS);
    const ordersDatacontent = orders && orders?.data && orders?.data?.getAllOrders?.response;
    const clientsData = useQuery(GET_ALL_CLIENTS);
    let cients = clientsData && clientsData?.data && clientsData?.data?.getAllClients?.response || [];

    const submit = (values) => {
        handleClose()

        const token = localStorage.getItem('token');

        const parseJwt = (token) => {
            try {
                return JSON.parse(atob(token.split(".")[1]));
            } catch (e) {
                return null;
            }
        };
        if(values.companyname == 'Blueplaint'){
        const valuesObject = {
            id: Math.random().toString(),
            userId: parseJwt(token)?.id,
            title: values.title,
            description: values.description,
            clientName: values.companyname,
            department: values.department,
            taskType: values.task,
            startTime: Date.now().toString(),
            endTime: Date.now().toString(),
        }
        localStorage.setItem('taskType', values.task);
        localStorage.setItem('StartTime', new Date().getTime());
        dispatch(timerHistoryPayloadChange({data: valuesObject}))
       
        setistaskRunning(true);
        settask(values.task)
    } else {
      let  temp = ordersDatacontent.filter(o => o.requestId == values.requestId);
        const valuesObject = {
            id: Math.random().toString(),
            userId: parseJwt(token)?.id,
            emailId: temp && temp?.length && temp[0].id || '',
            requestId: values.requestId,
            clientName: values.companyname,
            processType: values.serviceType,
            taskType: values.taskType || 'Processing',
            email: values?.email,
            subject: values?.subject,
            body: values?.body,
            startTime: Date.now().toString(),
            endTime: Date.now().toString(),
        }
        dispatch(timerHistoryPayloadChange({data: valuesObject}));
    }
    }


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            <BlueplanitModal
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <BlueplanitModalTitle id="customized-dialog-title" onClose={handleClose}>
                    Login Work History
                </BlueplanitModalTitle>
                <DialogContent dividers>
                    <Grid item xs={6} mt={2}>

                        <Formik
                            initialValues={initialValues}
                            onSubmit={submit}>
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>

                                        <Grid item xs={3} spacing={1}>
                                            <Stack >
                                            <FormControl sx={{ minWidth: 220 }}>
                                                <FormLabel>Company Name</FormLabel>
                                                {/* <FormLabel sx={{ pt: 2 }}><Typography fontWeight="bold">Blueplanit</Typography></FormLabel> */}
                                                <Select
                                                    value={values.companyname}
                                                    name='companyname'
                                                    // value={employeeDetails && employeeDetails['ProcessType']}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}>
                                                    <MenuItem key={'Blueplaint'} value={'Blueplaint'}>{'Blueplaint'}</MenuItem>
                                                    {cients.map((Item, key) => {
                                                        return (<MenuItem key={key} value={Item?.clientName}>{Item?.clientName}</MenuItem>)
                                                    })}

                                                </Select>
                                                </FormControl>
                                            </Stack>


                                        </Grid>
                                        { values.companyname == 'Blueplaint' &&
                                        <Grid item xs={3} spacing={1}>
                                            <FormControl sx={{ minWidth: 220 }}>
                                                <FormLabel>Department</FormLabel>

                                                <Select
                                                    value={values.processType}
                                                    name='department'
                                                    // value={employeeDetails && employeeDetails['ProcessType']}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}>
                                                    {DataBucket.TIMER_DEPARTMENT.map((Item, key) => {
                                                        return (<MenuItem key={key} value={Item}>{Item}</MenuItem>)
                                                    })}

                                                </Select>
                                            </FormControl>
                                        </Grid>}
                                        { values.companyname != 'Blueplaint' &&
                                        <Grid item xs={3} spacing={1}>
                                            <FormControl sx={{ minWidth: 220 }}>
                                                <FormLabel>Service</FormLabel>

                                                <Select
                                                    value={values.serviceType}
                                                    name='serviceType'
                                                    // value={employeeDetails && employeeDetails['ProcessType']}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}>

                                                    {DataBucket.PROCESS_TYPE_CONFIG.map((Item, key) => {
                                                        return (<MenuItem key={key} value={Item}>{Item}</MenuItem>)
                                                    })}

                                                </Select>
                                            </FormControl>
                                        </Grid>}
                                        { values.companyname == 'Blueplaint' &&
                                        <Grid item xs={3} spacing={1}>
                                            <FormControl sx={{ minWidth: 220 }}>
                                                <FormLabel>Tasks</FormLabel>

                                                <Select
                                                    value={values.taskType}
                                                    name='task'
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}>
                                                    {DataBucket.TIMER_TASKS.map((Item, key) => {
                                                        return (<MenuItem key={key} value={Item}>{Item}</MenuItem>)
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>}
                                        { values.companyname != 'Blueplaint' &&
                                        <Grid item xs={3} spacing={1}>
                                            <FormControl sx={{ minWidth: 220 }}>
                                                <FormLabel>Tasks</FormLabel>

                                                <Select
                                                    value={values.taskType}
                                                    name='task'
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}>
                                                    {DataBucket.TASKS_TO_CONFIG.map((Item, key) => {
                                                        return (<MenuItem key={key} value={Item}>{Item}</MenuItem>)
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>}
                                        { values.companyname != 'Blueplaint' &&
                                        <Grid item xs={3} spacing={1}>
                                            <InputLabel htmlFor="email-login">Request#</InputLabel>
                                            <OutlinedInput
                                                id="requestId"
                                                type="text"
                                                name="requestId"
                                                onChange={handleChange}
                                                placeholder="Enter Request"
                                                fullWidth
                                            />
                                    </Grid>}
                                    </Grid>
                                    {/* <Grid container spacing={2}> */}
                                    {/* <Grid item xs={6}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="email-login">Title</InputLabel>
                                            <OutlinedInput
                                                id="Title"
                                                type="text"
                                                name="title"
                                                onChange={handleChange}
                                                placeholder="Enter title"
                                                fullWidth
                                            />

                                        </Stack>


                                    </Grid> */}
                                    {/* </Grid>
                                    <Grid container spacing={2}> */}

                                    {/* <Grid item xs={6}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="email-login">Description</InputLabel>
                                            <OutlinedInput
                                                id="description"
                                                type="text"
                                                name="description"
                                                onChange={handleChange}
                                                placeholder="Enter description"
                                                fullWidth
                                                multiline
                                                maxRows={4}
                                                rows={4}
                                            />

                                        </Stack>
                                    </Grid> */}
                                    {/* </Grid> */}

                                    <DialogActions>
                                        <Button variant="outlined" onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <Button variant="contained" type="submit" >
                                            Save
                                        </Button>

                                    </DialogActions>
                                </form>
                            )}


                        </Formik>

                    </Grid>

                </DialogContent>

            </BlueplanitModal>
        </>
    )
}
export default LoginWorkHistory;